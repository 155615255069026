import React from 'react';
import styles from "./styles.module.css";
import { StyledHeading } from '../../HelperComponents';
import logo from "../../assets/api/lynkit_logo.svg";
import desktopBacklines from "../../assets/api/desktop_backlines.svg";
import mobileBacklines from "../../assets/api/mobile_backlines.svg";
import tabletBacklines from '../../assets/api/tablet_backlines.svg';
import nlds from '../../assets/api/nlds.svg';
import importexport from '../../assets/api/importexport.svg';
import indianrailway from '../../assets/api/indianrailway.svg';
import vahan from '../../assets/api/vahan.svg';
import fastag from '../../assets/api/fastag.svg'
import sarathi from '../../assets/api/sarathi.svg';
import nsdl from '../../assets/api/nsdl.svg' ;
import container from '../../assets/api/container.svg';
import aircargo from '../../assets/api/aircargo.svg';
import goodsandservice from '../../assets/api/goodsandservice.svg'
import getRoute from '../../assets/api/getRoute.svg'
import hubs from '../../assets/api/hubs.svg'
import contract from '../../assets/api/contract.svg'
import create_user from '../../assets/api/create_user.svg'
import setting from '../../assets/api/setting.svg'
import booking from '../../assets/api/booking.svg'
import suggestion from '../../assets/api/suggestion.svg'
import trip_creation from '../../assets/api/trip_creation.svg'
import dispatch from '../../assets/api/dispatch.svg'
const API = () => {

  const leftTiles = [
    // {
    //   id: "rto",
    //   icon: nlds,
    //   text: "Container Tracking via Logistics data bank",
    // },
    // {
    //   id: "monitoring_control",
    //   icon: importexport,
    //   text: "Installation and Servicing",
    // },
    // {
    //   id: "e2e_msp",
    //   icon: indianrailway,
    //   text: "FOIS",
    // },
    // {
    //   id: "data_transfer",
    //   icon: fastag,
    //   text: "Vehicle tracking using FasTAG ",
    // },
    // {
    //   id: "installation",
      
    //   icon: vahan,
    //   text: "VAHAN Authentication for vehicle authentication",
    // },
    {
      id: "route",
      icon: getRoute,
      text: `Get Routes`,
    },
    {
      id: "hub",
      icon: hubs,
      text: `HUB Creation`,
    },
    {
      id: "contract",
      icon: contract,
      text: `Create Contract`,
    },
    {
      id: "user",
      icon: create_user,
      text: `Create User`,
    },
    {
      id: "setting",
      icon: setting,
      text: `Setting API`,
    },
    {
      id:"booking",
      icon:booking,
      text: `Indent & Booking`
    },
    {
      id:"dispatch",
      icon:dispatch,
      text: `Dispatch`
    },
    {
      id:"trip_creation",
      icon:trip_creation,
      text: `Trip Creation`
    },
    {
      id:"suggestion",
      icon:suggestion,
      text: `Vehicle Suggestion`
    }
  ]
  const rightTiles = [
    {
      id: "fois",
      icon: sarathi,
      text: `Saarthi Authentication for Drive License`,
    },
    {
      id: "sim_tracking",
      icon: nsdl,
      text: "Permanent Account Number(PAN)",
    },
    // {
    //   id: "ldb",
    //   icon: ldb,
    //   text: "LDB (Live Departure Boards) Container Tracking",
    // },
    {
      id: "vessel_tracking",
      icon: container,
      text: "Container tracking via vessel status",
    },
    {
      id: "cargo_updates",
      icon: aircargo,
      text: "Air Cargo",
    },
    {
      id: "fastag",
      icon: goodsandservice,
      text: "Goods and service Tax identification Number",
    },
    {
      id: "rto",
      icon: nlds,
      text: "Container Tracking via Logistics data bank",
    },
    {
      id: "monitoring_control",
      icon: importexport,
      text: "Installation and Servicing",
    },
    {
      id: "e2e_msp",
      icon: indianrailway,
      text: "FOIS",
    },
    {
      id: "data_transfer",
      icon: fastag,
      text: "Vehicle tracking using FasTAG ",
    },
    {
      id: "installation",
      
      icon: vahan,
      text: "VAHAN Authentication for vehicle authentication",
    },
  ]

  return (

    <>
    <div  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:'10px'
        }}>
        <StyledHeading text="Lynkit's API" />
    </div >
    <div className={styles.main_container}>

      <div className={styles.api_section}>
        <div className={styles.left_side}>
          <span className={`${styles.title} ${styles.left_title}`}>Lynkit Internal APIs</span>
          {leftTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#eeeeee" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        <div className={styles.right_side}>
          <span className={`${styles.title} ${styles.right_title}`}>Third Party APIs</span>
          {rightTiles.map((tile, i) => {
            return (
              <div className={styles.api_box_container} id={styles[tile.id]}>
                <div className={styles.tile} style={{ background: "#fafafa" }}>
                  <img src={tile.icon} />
                </div>
                <p className={styles.tile_text}>{tile.text}</p>
              </div>
            )
          })}
        </div>
        {/* lynkit logo */}
        <div className={styles.logo_container}>
          <img src={logo} />
        </div>
        {/* background line structure */}
        <div className={styles.desktop_backlines}>
          <img src={desktopBacklines} />
        </div>
        <div className={styles.tablet_backlines}>
          <img src={tabletBacklines} />
        </div>
        <div className={styles.mobile_backlines}>
          <img src={mobileBacklines} />
        </div>
      </div>
    </div>
    
    </>
  )
}

export default API
