import React, { memo, useEffect } from "react";
import styles from "./style.module.css";
import successIcon from "./assets/success_green_icon.png";

// Toast Component
const MiddleCustomToast = ({ message, type, onClose }) => {
  
  return (
    // <div style={{}}>
      <div className={`${styles.middle_custom_toast}`}>
        <img src={successIcon} alt="Success" />
        <span className={styles.toast_msg}>{message}</span>
        <button 
          className={styles["middle_toast_close-btn"]} 
          onClick={onClose}
          aria-label="Close notification"
        >
          &times;
        </button>
      </div>
    // </div>
  );
};

export default memo(MiddleCustomToast);