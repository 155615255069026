// import React, { useEffect, useState } from 'react';
// import styles from './styles.module.css';


// export const FadeInComponent = ({ children, duration }) => {
//     const [visible, setVisible] = useState(false);
    
//     useEffect(() => {
//       const timer = setInterval(() => {
//         setVisible(true);
//       }, duration);
  
//       return () => clearTimeout(timer);
//     }, [duration]);
  
//     return (
//   <div className={`${styles.fade} ${visible ? styles['fade-in'] : styles['fade-out']}`}>
//         {children}
//       </div>
//     );
//   };
  
  
  
//   export const FadeOutComponent = ({ children, duration }) => {
//       const [visible, setVisible] = useState(true);
      
//       useEffect(() => {
//         const timer = setTimeout(() => {
//           setVisible(false);
//         }, duration);
    
//         return () => clearTimeout(timer);
//       }, [duration]);
    
//       return (
//         <div className={`${styles.fade} ${visible ? styles['fade-in'] : styles['fade-out']}`}>
//           {children}
//         </div>
//       );
//     };
  
  
  

//   // Slide Components
//   export const SlideLeftComponent = ({ children, duration }) => {
//       const [visible, setVisible] = useState(false);
    
//       useEffect(() => {
//         const timer = setTimeout(() => {
//           setVisible(true);
//         }, duration);
    
//         return () => clearTimeout(timer);
//       }, [duration]);
    
//       return (
//         <div className={`${styles.slide} ${visible ? styles['slide-in-left'] : styles['slide-out-left']}`}>
//           {children}
//         </div>
//       );
//     };
  
  
 
    
   
      
//     export const SlideUpComponent = ({ children, duration }) => {
//       const [visible, setVisible] = useState(false);
    
//       useEffect(() => {
//         const timer = setTimeout(() => {
//           setVisible(true);
//         }, duration);
    
//         return () => clearTimeout(timer);
//       }, [duration]);
    
//       return (
//         <div className={`${styles.slide} ${visible ? styles['slide-in-up'] : styles['slide-out-up']}`}>
//           {children}
//         </div>
//       );
//     };
        
//     export const SlideDownComponent = ({ children, duration }) => {
//       const [visible, setVisible] = useState(false);
    
//       useEffect(() => {
//         const timer = setTimeout(() => {
//           setVisible(true);
//         }, duration);
    
//         return () => clearTimeout(timer);
//       }, [duration]);
    
//       return (
//         <div className={`${styles.slide} ${visible ? styles['slide-in-down'] : styles['slide-out-down']}`}>
//           {children}
//         </div>
//       );
//     };


    

import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';


export const SlideSimpleRightComponent = ({ children, duration }) => {
    const [visible, setVisible] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setVisible(true);
      }, duration);
  
      return () => clearTimeout(timer);
    }, [duration]);
  
    return (
      <div className={`${styles.slide} ${visible ? styles['slide-in-right'] : styles['slide-out-right']}`}>
        {children}
      </div>
    );
  };



// Fade In Component
export const FadeInComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.fade} ${visible ? styles['fade-in'] : styles['fade-out']}`}
            style={{ transitionDuration: `${duration}ms`, display:'flex', justifyContent:'center' }}
        >
            {children}
        </div>
    );
};

// Fade Out Component
export const FadeOutComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.fade} ${visible ? styles['fade-in'] : styles['fade-out']}`}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {children}
        </div>
    );
};

// Slide Left Component
export const SlideLeftComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            // { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.slide} ${visible ? styles['slide-in-left'] : styles['slide-out-left']}`}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {children}
        </div>
    );
};

// Slide Right Component
export const SlideRightComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            // { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.slide} ${visible ? styles['slide-in-right'] : styles['slide-out-right']}`}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {children}
        </div>
    );
};

// Slide Up Component
export const SlideUpComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.slide} ${visible ? styles['slide-in-up'] : styles['slide-out-up']}`}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {children}
        </div>
    );
};

// Slide Down Component
export const SlideDownComponent = ({ children, duration = 1000 }) => {
    const ref = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                } else {
                    setVisible(false); // Reset visibility when out of view
                }
            },
            { threshold: 0.1 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`${styles.slide} ${visible ? styles['slide-in-down'] : styles['slide-out-down']}`}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {children}
        </div>
    );
};
