import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
// import mainStyles from "../../main.module.css";
import { StyledHeading } from '../../HelperComponents';
import freightIcon2 from '../../assets/otherFeature/freightIcon2.svg'
import freightImg from '../../assets/otherFeature/freight_contact_management.svg'
import driverIcon3 from '../../assets/otherFeature/driverIcon3.svg'
import driverImg from '../../assets/otherFeature/driver_management.svg'
import loadIcon5 from '../../assets/otherFeature/loadIcon5.svg'
import loadImg from '../../assets/otherFeature/loadImg.svg'
import routetracking6 from '../../assets/otherFeature/routetracking6.svg'
import routetrackingImg from '../../assets/otherFeature/route_tracking.svg'
import indentingIcon7 from '../../assets/otherFeature/indentingIcon7.svg'
import indentingImg from '../../assets/otherFeature/indentingImg.svg'
import inplantIcon8 from '../../assets/otherFeature/inplantIcon8.svg'
import inplantImg from '../../assets/otherFeature/inplant.jpg'
import documentIcon9 from '../../assets/otherFeature/documentIcon9.svg'
import documentimg from '../../assets/otherFeature/documentCreation.svg'
import LoadAiImg from '../../assets/otherFeature/load.svg'
import endtoendIcon from '../../assets/otherFeature/endtoendIcon.svg'
import endtoendImg from '../../assets/otherFeature/endtoendImg.svg'
import routeandloadopti from '../../assets/otherFeature/routeandloadopti.svg'
import { SlideRightComponent } from '../../AnimationConents';
const Tab = ({ info, active, onClick }) => {
  return (
    <div
      className={styles.feature_tab}
      style={{
        background: active ? "#FF7700" : "#FFFFFF",
        color: active ? "#FFFFFF" : "#595959", 
        
      }}
      onClick={onClick}
    >
      <img
        src={info?.icon}
        style={{
          filter: active ? "invert(100%)" : "none" 
        }}
      />
      <span className={styles.title}>
        {info?.title}
      </span>
    </div>
  );
};


const OtherFeature = () => {
const [current, setCurrent]=useState(0)
const[animate, setAnimate]=useState(false)
  const otherfeatures = [
    {
      title: "Freight Contract Management",
      icon: freightIcon2,
      img: freightImg,
      text: `You can use our Tranport Management System (TMS) to create and share your freight contracts and enter all the terms for invoice creation, penalty imposition, indent/vehicle placement, etc. The fleet management system will monitor the progress of the contract until it is closed and notify you in case of any contract violations.`   
    },

    {
      title: "Route Tracking and Planning",
      icon: routetracking6,
      img: routetrackingImg,
      text: `For delivery, the onboarded drivers can be easily directed to ensure timely delivery management with LynkNav, Lynkit's last-mile management product integrated with TMS software. Once the transporters allocate the drivers, they can see the routes assigned, the customer address, contact number, ETA, traffic, delivery instructions, and the best possible route.`
    },

    {
      title: "Driver Management",
      icon: driverIcon3,
      img: driverImg,
      text: `Driver management helps create a digital ledger for all drivers to record their KYC details and maintain records of their expenses, attendance, traffic violations, and customer complaints. Drivers can use our Transport Management System (TMS) to access and map each trip for backtracking and salary payment automation.`
    },

    {
      title: "Route and Load Optimization",
      icon: routeandloadopti,
      img: loadImg,
      text: `The route optimizer creates optimal routes. Drivers are given access to the same via the mobile app, and it is linked with live tracking, allowing for management of delivery time or date slots, improving overall freight management.`
    },

    {
      title: "Load and Vehicle Planning using AI",
      icon: loadIcon5,
      img:LoadAiImg,
      text: `This module uses artificial intelligence and predictive algorithms to create the ideal loading plan. Orders and production plans can be integrated from your ERP, and the Transport Management System (TMS) can show a cost comparison between various fleets/transporters based on created contracts, improving fleet management.`
    },

    {
      title: "In-plant Logistics",
      icon: inplantIcon8,
      img: inplantImg,
      text: `Lynkit's IoT layers allow you to create your plant into a digital fortress. The hardware integrates with the TMS to allow access only to verified vehicles in the plant. Integrations with weighbridges allow cross-checking and auto-populating freight amounts and loads to avoid post-departure surprises. The system provides a complete parking management system, giving you insights into loading and unloading times and routing vehicles to the correct slots, supporting better freight management.`
    },

    {
      title: "Document Creation For Trips",
      icon: documentIcon9,
      img: documentimg,
      text: `Our Transport Management System (TMS) can generate all corresponding transport documentation such as invoices, lorry receipts, e-way bills, and consolidation challans. Logistics teams can send service requests to the transporters via this platform with all shipment details. With our Fleet Management system in place, all transporters can access your shipment information directly through their accounts and generate the required documents.`
    },

    {
			title: "End to End SKU Tracking",
			icon: endtoendIcon,
			img: endtoendImg,
			text: `Our Transport Management System (TMS) enables real-time tracking within plants and warehouses via RFID-based movement tracking, in-transit tracking of vehicles via GPS tracking using Lynkit trackers, integration of other vendor trackers to a single portal, and SIM-based or mobile app driver mobile tracking.`,
		},  
    // {
    //   title: "Route and Trip Creation and Budgeting",
    //   icon: routeIcon5,
    //   img: routeImg,
    //   text: `Our route master feature within the transport management system allows you to optimize routes with route names, route sources, and destinations with multiple via points and map them with your trips, vehicles, and expenses.`
    // },
    // {
   
    
  ];

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 1000); // Match this duration with your CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [animate]);


  const handleTabClick=(i)=>{
    setCurrent(i);
    setAnimate(true)

  }

  return (
    <div className={styles.software_features_section}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledHeading text="Other Features" />
      </div>
      <div className={`${styles.features_outer_container} ${styles.large_screens}`}>
        <div className={styles.feature_tabs}>
          {otherfeatures?.map((feature, i) => {
            return (
              <Tab
                info={feature}
                active={i === current ? true : false}
                onClick={() => handleTabClick(i)}
                key={i}
              />
            );
          })}
        </div>

        <div className={styles.feature_content}>
          <div className={styles.text_content}>
            <span>{otherfeatures[current].title}</span>
            <p>{otherfeatures[current].text}</p>
          </div>
          <div className={styles.img_content}>
            {animate?
            <SlideRightComponent duration={1000}>
                <img src={otherfeatures[current].img} />
            </SlideRightComponent>
           :
           <img src={otherfeatures[current].img} />
           }
          </div>
        </div>

      </div>
      <div className={`${styles["slider-container"]} ${styles.small_screens}`}>
        <div className={`${styles["slider-wrapper"]}`}>
          {otherfeatures?.map((feature, i) => {
            return (
              <div className={`${styles["slider-item"]}`}>
                <div className={styles["title-row"]}>
                  <img src={feature.icon} />
                  <h3>{feature.title}</h3>
                </div>
                <hr style={{border: "1px solid #ff7700", opacity: 1}} />
                <p style={{fontSize: "14px"}}>{feature.text}</p>
                <img src={feature?.img} alt="Geofencing" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OtherFeature