import React from "react";
import styles from "./styles.module.css";
import Accordian from "./Accordian";
import Title from "../../helper_components/title";
import { useHistory } from "react-router-dom";
const FAQs = () => {
		const history = useHistory();
	return (
		<>
			<div className={styles.mobileHeading}>
                <Title text="Why should you use @Lynkit @XBL?"/>	
                <div className={styles.gradient_line}></div>
			</div>
			<div className={styles.main}>
				<div className={styles.leftbox}>
					<div className={styles.heading}>
						Why should you use <span style={{ color: "#ff7700" }}>Lynkit XBL</span>?
					</div>
					<div>
						<button
							className={styles.filled_orange}
							onClick={() => {
								// window.open("https://www.lynkit.in/ContactUs", "_blank");
								history.push("/xeneta/contact");
							}}
						>
							Request a Demo <span style={{ fontSize: "12px" }}>&gt;</span>
						</button>
					</div>
				</div>
				<div className={styles.container}>
					<Accordian />
				</div>
			</div>
		</>
	);
};

export default FAQs;
