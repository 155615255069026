import React from "react";
import mainStyles from "../../main.module.css";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import logistics3pls from "../../assets/industries/logistics3pl.svg";
import warehouse_dist from "../../assets/industries/warehousing.svg";
import automobiles from "../../assets/industries/automobiles.svg";
import export_import from "../../assets/industries/export_import.svg";
import consumer_elec from "../../assets/industries/consumer_elec.svg";
import public_sec_govt from "../../assets/industries/public_sec_govt.svg";
import ecom from "../../assets/industries/ecom.svg";
import container_terminals from "../../assets/industries/container_terminals.svg";
import mining_metals from "../../assets/industries/mining_metals.svg";
import railways from "../../assets/industries/railways.svg";
import shipping_lines from "../../assets/industries/shipping_lines.svg";
import public_transport from "../../assets/industries/public_transport.svg";
import d2c from "../../assets/industries/d2c.svg";
import security from "../../assets/industries/security.svg";
import oil_gas_energy from "../../assets/industries/oil_gas_energy.svg";
import air_cargo from "../../assets/industries/air_cargo.svg";
import it from "../../assets/industries/it.svg";
import bonded_warehouse from '../../assets/industries/bonded_warehouse.svg'
import { FadeInComponent, SlideLeftComponent, SlideRightComponent } from "../../AnimationConents";

const Industries = ({ current, setCurrent }) => {
	const industries = [
		{
			name: "Logistics and 3PLs",
			img: logistics3pls,
			content: [
				"Contract management",
				"e-LR and doc management",
				"Vehicle and driver management",
				"Route optimisation",
				"Fuel management and expense tracking",
				"VAHAN and saarthi integrations",
			],
		},
		{
			name: "Warehousing and Distribution",
			img: warehouse_dist,
			content: ["Linkage of inventory with vehicle identity and trips for end to end tracking", "Yard and dock management for quicker TATs", "Vehicle slot management Systems"],
		},
		{
			name: "Public Sector and Government",
			img: public_sec_govt,
			content: ["Supply chain tracking for all supplies of goods, medicines, food etc.", "Freight bills and contract management","End-to-end tracking of government shipments, including medical supplies, food distribution, and relief materials"],
		},
		// {
		// 	name: "Marketplace",
		// 	img: marketplace,
		// 	content: [],
		// },
		{
			name: "D2C",
			img: d2c,
			content: ["Customer delivery feedback", "Geotagged and time based dispute resolution","Efficiently managing returns, exchanges, and pickups with automated workflows"],
		},
		// {
		// 	name: "Pharma and Healthcare",
		// 	img: pharma,
		// 	content: [],
		// },
		{
			name: "Automobile",
			img: automobiles,
			content: ["Car carrier optimisation for vehicle placement","Real-time tracking of newly manufactured vehicles from factories to dealerships","Managing vehicle movement across road, rail, and sea for cost-effective distribution","Managing the transportation of lithium-ion batteries with compliance and safety checks"],
		},
		{
			name: "E-commerce",
			img: ecom,
			content: ["Contract management", "e-LR and doc management", "Vehicle and driver management"],
		},
		{
			name: "Railways",
			img: railways,
			content: ["e-railway receipts", "FOIS integration and APIs","Monitoring temperature-controlled rail containers for food and pharma logistics","Optimizing wagon allocation, loading, and movement to maximize efficiency"]
		},
		{
			name: "Security",
			img: security,
			content: ["Digital proof and ledger of all vehicle movements","Real-time GPS tracking for the secure transportation of cash, gold, and confidential documents",	"Instant alerts if a vehicle deviates from the designated route.Instant alerts if a vehicle deviates from the designated route"	,"Integration with smart GPS locks to prevent unauthorized access to shipments"],
		},
		// {
		// 	name: "Gems and Jewellery",
		// 	img: jewellery,
		// 	content: [],
		// },
		{
			name: "Export and Import",
			img: export_import,
			content: ["International e-PODs", "IEC integrations","Ensuring cargo integrity with tamper-proof smart locks","Automating paperwork for smooth customs clearance and regulatory adherence"],
		},
		{
			name: "Container Terminals",
			img: container_terminals,
			content: ["Gate pass generations", "Trip management"," Live monitoring of container movements within and outside the terminal", " AI-driven insights to prevent bottlenecks in terminal operations"],
		},
		{
			name: "Shipping Lines",
			img: shipping_lines,
			content: ["e-Bill of lading", "Linkage of transport documents with shipping bill and bill of entry documents","Real-time temperature and humidity tracking for perishable goods", "Monitoring vessel entry/exit at ports and restricted areas to enhance security"],
		},
		{
			name: "Oil, Gas and Energy",
			img: oil_gas_energy,
			content: ["Supplier and Distribution Management System", " Contract and billing automation for all tanker and LPG deliveries", " Product tracking and authentivity checks"],
		},
		{
			name: "IT",
			img: it,
			content: ["SDKs for integrations with ERPs","Managing IT asset transfers between offices, data centers, and warehouses","Streamlining returns, replacements, and disposal of outdated or faulty IT hardware","AI-powered route planning for faster and more efficient deliveries"],
		},
		{
			name: "Consumer Electronics",
			img: consumer_elec,
			content: ["Supplier and Distribution Management System","Real time tracking of electronic goods from factories to warehouses, retailers, and customers","GPS tracking and smart locks to prevent theft and tampering of expensive electronics"],
		},
		{
			name: "Mining and Metals",
			img: mining_metals,
			content: ["End to end Transport management", "Contracts with all carriers and freight billing", "Indent management", "VAHAN and Saarthi integrations"],
		},
		{
			name: "Public Transport Networks",
			img: public_transport,
			content: ["Automated billing and invoicing","GPS-based live tracking of buses, metros, and trams for improved operational visibility", "AI-driven dynamic route planning to reduce congestion and enhance efficiency", "Providing accurate estimated arrival and departure times for commuters"],
		},
		{
			name: "Air Cargo",
			img: air_cargo,
			content: ["Plane load plan optimizer", "ULD optimizer", "Pallet builder and optimiser"],
		},
		{
			name: "Bonded Warehouses",
			img: bonded_warehouse,
			content: ["Product tracking, digital ledger of all trips and vehicle movements linked with bonded documents","Real-time tracking of goods entering, stored in, and exiting bonded warehouses", "Compliance with duty suspension regulations and seamless customs clearance", "Temperature-controlled storage and transport of duty-free perishables" ],
		},
	];
	return (
		<div className={styles.outer_container}>
			<StyledHeading text="Industries" />
			<div className={styles.slides_area}>
				<div className={styles.tab_container_outer}>
					{industries.map((industry, i) => {
						return (
							<div
								className={styles.tab}
								style={{
									background: current === i ? "#ff7700" : "#ffffff",
									color: current === i ? "#ffffff" : "#747576",
								}}
								onClick={() => setCurrent(i)}
							>
								{industry?.name}
							</div>
						);
					})}
				</div>
				{industries?.map((industry, i) => {
					if (i === current) {
						return (
							<div className={styles.info_section}>
								<div className={styles.details}>
									{/* <div className={styles.details_title}>
										<span>{industry?.name}</span>
									</div> */}
									<div className={styles.details_content}>
										{window.innerWidth < 800 ? (
											<FadeInComponent duration={2000}>
												<SlideLeftComponent duration={1000}>
													<ul>
														{industry?.content?.map((item, i) => {
															return <li key={i}>{item}</li>;
														})}
													</ul>
												</SlideLeftComponent>
											</FadeInComponent>
										) : (
											<ul>
												{industry?.content?.map((item, i) => {
													return <li key={i}>{item}</li>;
												})}
											</ul>
										)}
									</div>
									<button
										className={`${mainStyles.custom_button} ${mainStyles.filled_orange}`}
										onClick={() => {
											window.open("https://lynkit.in/ContactUs", "_blank");
										}}
									>
										Know More
									</button>
								</div>

								{window.innerWidth < 800 ? (
									<div className={styles.img_container}>
										<img src={industry.img} />
										<div className={styles.title_over_img}>
											<span>{industry?.name}</span>
										</div>
									</div>
								) : (
									<SlideRightComponent duration={1000}>
										<div className={styles.img_container}>
											<img src={industry.img} />
											{/* <div className={styles.title_over_img}>
												<span>{industry?.name}</span>
											</div> */}
										</div>
									</SlideRightComponent>
								)}
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		</div>
	);
};

export default Industries;
