import React, { useState } from "react";
import cloud from "../../../assets/supplyChain/supply_chain_cloud.webp";
import styles from "./components.module.css";
import mainStyles from "../supply_chain.module.css";
import { FadeInComponent } from "../../../AnimationConents";
import mainSectionImg from "../../../assets/supplyChain/mainSection.svg";
import { motion } from "framer-motion";
import RoundObj from "./sub-components/RoundObj";
import main_img from "../../../assets/supplyChain/supply_chain_main.png";
import main_img1 from "../../../assets/supplyChain/supply_chain_main1.svg";
const MainSection = ({ setActive }) => {
	const [isScaling, setIsScaling] = useState(false); // State for scaling transition

	// Handle click for External Stakeholders
	const handleClick = () => {
		setIsScaling(true); // Start the scaling effect
		setTimeout(() => {
			setActive(2); // Change active tab after delay
		}, 1000); // Time delay after the scale transition ends
	};

	// Handle click for Internal Stakeholders
	const handleClick2 = () => {
		setIsScaling(true); // Start the scaling effect
		setTimeout(() => {
			setActive(1); // Change active tab after delay
		}, 1000); // Time delay after the scale transition ends
	};

	return (
		// <FadeInComponent duration={1500}>
		// 	<motion.div
		// 		className={styles.main_section_container}
		// 		initial={{ scale: 1 }}
		// 		animate={{ scale: isScaling ? 0 : 1 }} // Scale down the entire container
		// 		transition={{
		// 			scale: { duration: 1, ease: "easeInOut" }, // Smooth scale-in effect
		// 		}}

		// 	>
		// 		<div className={styles.stake_Container}>
		// 			{/* External Stakeholders Button */}
		// 			<div
		// 				className={styles.stakeholder_title}
		// 				onClick={handleClick}
		//         id={`${styles.clickableDiv}`}
		// 			>
		// 				External <br /> Stakeholders
		// 			</div>

		// 			{/* Internal Stakeholders Button */}
		// 			<div
		// 				className={styles.stakeholder_title}
		// 				onClick={handleClick2}
		//         id={`${styles.clickableDiv}`}
		// 			>
		// 				Internal <br />
		// 				Stakeholders
		// 			</div>
		// 		</div>

		// 		{/* Cloud Image without Animation */}
		// 		<div className={styles.cloud_container}>
		// 			<img src={cloud} alt="Cloud" height="100%" width="100%" />
		// 		</div>

		// 		{/* Optional Additional Image or Content */}
		// 		<div className={styles.imgContainer}>
		// 			<img src={mainSectionImg} />
		// 		</div>
		// 	</motion.div>
		// </FadeInComponent>
		<motion.div className={styles.main_section_container}>
			<div className={styles.buttons_container}>
				<div className={styles.wrapper}>
					<button onClick={() => setActive(2)} style={{ textAlign: "center" }} className={styles.extIntbutton}>
						External Stakeholders
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
				<div className={styles.wrapper}>
					<div onClick={() => setActive(1)} style={{ textAlign: "center", paddingTop:'15px'}} className={styles.extIntbutton}>
						Internal Stakeholders
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
			<div className={styles.diagram_container}>
				<motion.div key="main_flow" className={styles.main_sec_flowchart} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.6 }}>
					<img src={main_img} width="100%" height="100%" />
				</motion.div>
				<motion.div key="main_flow" className={styles.main_sec_flowchart_mobile} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.6 }}>
					<img src={main_img1} width="100%" height="100%" />
				</motion.div>

				<RoundObj showGradient={false} />
			</div>
		</motion.div>
	);
};

export default MainSection;
