import React from "react";
import styles from "./styles.module.css";
import Title from "../../helper_components/title";
import comprehensiveImg from "../../assets/features/comprehensiveImage.svg";
import comprehensiveIcon from "../../assets/features/comprehensiveIcon.png";
import proactiveIcon from "../../assets/features/proactiveIcon.svg";
import realtimeIcon from "../../assets/features/realtimeIcon.svg";
import transparentIcon from "../../assets/features/transparentIcon.svg";
import proactiveIconOrange from "../../assets/features/proactiveIconOrange.svg";
import realtimeIconOrange from "../../assets/features/realtimeIconOrange.svg";
import transparentIconOrange from "../../assets/features/transparentIconOrange.svg";
import comprehensiveOrangeIcon from "../../assets/features/comprehensiveOrangeIcon.png";
import proactiveImage from '../../assets/features/proactiveImage.svg'
import realTimeImage from '../../assets/features/realTimeImage.svg'
import transparentImage from '../../assets/features/transparentImage.svg';
import { useHistory } from "react-router-dom";
import {motion} from 'framer-motion';

const Tab = ({ info, active, onClick }) => {
	return (
		<motion.div 
			className={styles.feature_tab} 
			id={`kf_${info?.title}`}
			initial={false}
			animate={{
				color: active ? "#FF7700" : "", 
				borderColor: active ? "#FF7700" : "#A09A9A",
			}}
			transition={{duration: 0.2, delay: 0.2}}
			style={{ 
				// color: active ? "#FF7700" : "", 
				// borderColor: active ? "#FF7700" : "#A09A9A", 
				position: "relative" 
			}} 
			onClick={onClick}
		>

			{active ? <img src={info.iconOrange} /> : <img src={info?.icon} />}
			<span className={styles.title} style={{ color: active ? "#FF7700" : "#A09A9A" }}>
				{info?.title}
			</span>
			{active && (
				<motion.div className={styles.cornerDots}>
					<div className={styles.bottomLeft}></div>
					<div className={styles.bottomRight}></div>
				</motion.div>
			)}
		</motion.div>
	);
};

const Features = ({ current, setCurrent }) => {
	const history = useHistory();
	const features = [
		{
			title: "Comprehensive Freight Data",
			icon: comprehensiveIcon,
			iconOrange: comprehensiveOrangeIcon,
			img: comprehensiveImg,
			text: `Sea freight: Access over 500 million ocean freight rates covering 170,000+ port pairs. Benchmark your rates against market prices for 20’ & 40’ standard, reefer, tank and high cube containers to ensure you always get the best deal. \n\nAir freight: Access over 60,000 airport pair data allowing you to compare rates across multiple airlines, TATs and service levels. `,
		},
		{
			title: "Real-Time Freight Market Insights",
			icon: realtimeIcon,
			iconOrange: realtimeIconOrange,
			img: realTimeImage,
			text: `Stay updated with the latest market trends, surcharges, and price movements. Lynkit XBL gives you detailed insights into rate fluctuations and helps you anticipate changes in market conditions, ensuring you're always a step ahead.`,
		},
		{
			title: "Proactive Risk Management",
			icon: proactiveIcon,
			iconOrange: proactiveIconOrange,
			img: proactiveImage,
			text: `Identify potential disruptions, such as regulatory changes, macroeconomic events, or capacity shortages, before they affect your freight procurement. Lynkit XBL helps you prepare for risks and adjust your strategy in real-time.`,
		},
		{
			title: "Transparent Surcharge Data",
			icon: transparentIcon,
			iconOrange: transparentIconOrange,
			img: transparentImage,
			text: `Gain visibility into surcharge data for fuel, congestion, and peak seasons. With XBL - Lynkit, you’ll understand the full scope of your shipping costs, making it easier to budget and plan for fluctuating expenses. Moreover you will be able to see the true impact of surcharges and see what the market is truly accepting as a price hike!`,
		},
	];
	return (
		<>
            <Title text='Key Features of @Lynkit @XBL' />
            <div className={styles.gradient_line}></div>
			<div className={styles.software_features_section}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "50px",
					}}
				></div>
				<div className={`${styles.features_outer_container} ${styles.large_screens}`}>
					<div className={styles.feature_tabs}>
						{features?.map((feature, i) => {
							return <Tab info={feature} active={i === current ? true : false} onClick={() => setCurrent(i)} key={i} />;
						})}
					</div>

					<motion.div 
						key={current}
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.3 }}
						className={styles.feature_content}
					>
						<div className={styles.text_content_sf}>
							<span>{features[current].title}</span>
							<p>{features[current].text}</p>
						</div>
						<div className={styles.img_content}>
							<img src={features[current].img} />
						</div>
					</motion.div>
					<div className={styles.outline_container}>
						<button
							className={`${styles.custom_button} ${styles.filled_orange}`}
							onClick={() => {
								// window.open("https://lynkit.in/ContactUs", "_blank");
								history.push("/xeneta/contact");
							}}
						>
							Request A Demo
						</button>
					</div>
				</div>
				<div className={`${styles["slider-container"]} ${styles.small_screens}`}>
					<div className={`${styles["slider-wrapper"]}`}>
						{features?.map((feature, i) => {
							return (
								<div className={`${styles["slider-item"]}`}>
									<div className={styles["title-row"]}>
										<img src={feature.iconOrange} />
										<h3>{feature.title}</h3>
									</div>
									<hr style={{ border: "1px solid #ff7700", opacity: 1 }} />
									<p style={{ fontSize: "14px" }}>{feature.text}</p>
									<img src={feature?.img} alt="Geofencing" />
								</div>
							);
						})}
					</div>
				</div>

				<div className={styles.button_small_screen}>
					<button
						className={`${styles.custom_button} ${styles.filled_orange}`}
						onClick={() => {
							// window.open("https://lynkit.in/ContactUs", "_blank");
							history.push("/xeneta/contact");
						}}
					>
						Request A Demo
					</button>
				</div>
				<div className={styles.small_screens_button_outliner}></div>
			</div>
		</>
	);
};

export default Features;
