import React, { useEffect, useState } from "react";
import styles from "./components.module.css";
import external_grp from "../../../assets/supplyChain/external_group.svg";
import cloud from "../../../assets/supplyChain/cloud.svg";
import { FadeInComponent } from "../../../AnimationConents";
import { motion } from "framer-motion";
import RoundObj from "./sub-components/RoundObj";


const staggerContainerAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5, // Add a delay between each content box (can be adjusted)
    },
  },
};

const contentBoxAnimation = {
  hidden: { opacity: 0, y: 20 }, // Start from opacity 0 and position it slightly lower (y: 20)
  show: {
    opacity: 1,
    y: 0, // End at opacity 1 and normal position
    transition: {
      duration: 1.5, // Duration of the animation
    },
  },
};

const ExternalStakeholders = ({ setActive }) => {
  const [scaleUp, setScaleUp] = useState(false); // State for scale-up transition
  const [cloudAnimation, setCloudAnimation] = useState(false);

  // Trigger scaling when setActive is called
  useEffect(() => {
    if (setActive) {
      setScaleUp(true); // Trigger scale-up when setActive is called
    }
  }, [setActive]);

  // Trigger cloud animation on mount
  useEffect(() => {
    setCloudAnimation(true);
  }, []);

  return (
    <>
      {/* <FadeInComponent duration={2500}> */}
        <motion.div
          className={styles.external_container}
          initial={{ scale: 0 }} // Start scale at 0
          animate={{ scale: scaleUp ? 1 : 1 }} // Scale up only when setActive is triggered
          transition={{ duration: 1, ease: "easeInOut" }} // Smooth scale-up transition
          style={{margin:'3rem 0rem'}}
        >
          {/* <div className={styles.content_box1}>
            <p>
              Incoming stock visibility, route and load optimization, and
              access control.
            </p>
          </div>
          <div className={styles.content_box2}>
            <p>
              Integration of Bill of Loading, Shipping Bill, and Bill of Entry;
              vessel tracking in transit.
            </p>
          </div>
          <div className={styles.content_box3}>
            <p>
              Vehicle and driver management, expense management, and service
              scheduling.
            </p>
          </div>
          <div className={styles.content_box4}>
            <p>
              Tracking and updates, exception reporting, dispute resolution,
              and real-time visibility.
            </p>
          </div>
           */}
             <motion.div
    className={styles.content_box_container}
    initial="hidden"
    animate="show"
    variants={staggerContainerAnimation}
  >
           <motion.div className={styles.content_box1} variants={contentBoxAnimation}>
      <p>
        Incoming stock visibility, route and load optimization, and access control.
      </p>
    </motion.div>
    <motion.div className={styles.content_box2} variants={contentBoxAnimation}>
      <p>
        Integration of bill of loading, shipping bill, and bill of entry; vessel tracking in transit.
      </p>
    </motion.div>
    <motion.div className={styles.content_box3} variants={contentBoxAnimation}>
      <p>
        Vehicle and driver management, expense management, and service scheduling.
      </p>
    </motion.div>
    <motion.div className={styles.content_box4} variants={contentBoxAnimation}>
      <p>
        Tracking and updates, exception reporting, dispute resolution, and real-time visibility.
      </p>
    </motion.div>
    </motion.div>
          <div className={styles.roundContainer} style={{transform: "scale(0.75)"}}>
          <RoundObj/>
          <div className={styles.l_shaped_line}></div>
          <div className={styles.l_shaped_line2}></div>
          <div className={styles.l_shaped_line3}></div>
          <div className={styles.l_shaped_line4}></div>
          </div>
          {/* <img src={external_grp} className={styles.s2Dtop} /> */}
          {/* Cloud Image without Animation */}
          {/* <div className={styles.external_dcloudimg}>
            <img src={cloud} height="100%" width="100%" />
          </div> */}
        </motion.div>
      {/* </FadeInComponent> */}

      <FadeInComponent duration={2500}>
        <div className={styles.mslide2}>
          <div className={styles.consignor}>External</div>
          <div className={styles.cloud}>
            <img src={cloud} />
          </div>

          <div className={styles.mbox_container}>
            <div className={`${styles.mcontainer} ${styles.mbox3}`}>
              <div className={styles.msection}>
                <h3>Warehouse</h3>
                <p>
                  Incoming stock visibility, route and load optimization, and
                  access control.
                </p>
              </div>
              <div className={styles.mdivider}></div>
              <div className={styles.msection}>
                <h3>Transporter</h3>
                <p>
                  Vehicle and driver management, expense management, and
                  service scheduling.{" "}
                </p>
              </div>
              <div className={styles.mdivider}></div>
              <div className={styles.msection}>
                <h3>Agent</h3>
                <p>
                  Integration of bill of loading, shipping bill, and bill of
                  entry; vessel tracking in transit.
                </p>
              </div>
              <div className={styles.mdivider}></div>
              <div className={styles.msection}>
                <h3>Customer</h3>
                <p>
                  Tracking and updates, exception reporting, dispute
                  resolution, and real-time visibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FadeInComponent>
    </>
  );
};

export default ExternalStakeholders;
