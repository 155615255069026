import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import pdfFile from "./WMS_Ebook.pdf";
import CustomToast from "../CustomToast";

const EbookContactForm = ({ open, setOpen, showSuccessToastFn }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    number: "",
  });
  const [toast, setToast] = useState({ message: "", type: "" });

  const showToastFn = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast({ message: "", type: "" }), 5000);
  };

  const onClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.company_name.trim()) {
      newErrors.company_name = "Company Name is required.";
    }

    const alphabetRegex = /^[a-zA-Z ]+$/;
    if (!formData.first_name.trim()) {
      newErrors.first_name = "First name is required.";
    } else if (!alphabetRegex.test(formData.first_name)) {
      newErrors.first_name = "Enter a valid first name.";
    }

    if (formData.last_name.trim() && !alphabetRegex.test(formData.last_name)) {
      newErrors.last_name = "Enter a valid last name.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    const numberRegex = /^\d{10}$/;
    if (!formData.number) {
      newErrors.number = "Phone Number is required.";
    } else if (!numberRegex.test(formData.number)) {
      newErrors.number = "Enter a valid phone number.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "WMS_ebook.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prevent multiple submissions
    if (loading) return;

    if (validate()) {
      setLoading(true);

      // Clear form and close modal first
      setFormData({
        company_name: "",
        first_name: "",
        last_name: "",
        email: "",
        number: "",
      });

      const payload = {
        ...formData,
        interest: ["WMS ebook download"],
      };

      setLoading(true);

      axios({
        url: "https://api.lynkit.in/api/ebook",
        method: "POST",
        data: payload,
      })
        .then((res) => {
          setLoading(false);
          if (res?.data?.success) {
            // handleDownloadPdf();
            setFormData({
              company_name: "",
              first_name: "",
              last_name: "",
              email: "",
              number: "",
            });
            setOpen(false);
            showSuccessToastFn(
              "Thanks for your interest in our eBook! We'll be sending it to you via email shortly. Hope you enjoy the read!",
              "success"
            );
          } else {
            showToastFn(res?.data?.message || "Some Error Occurred.", "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          showToastFn("Some Error Occurred.", "error");
        });
    }
  };

  useEffect(() => {
    if (open) {
      setFormData({
        company_name: "",
        first_name: "",
        last_name: "",
        email: "",
        number: "",
      });
      setToast({ message: "", type: "" });
    }
    setErrors({});
  }, [open]);

  return (
    <>
      {open ? (
        <div className={styles.backdrop} onClick={onClose}>
          <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
            <div
              className={`${styles.flex_c}`}
              style={{ justifyContent: "flex-end", position: "sticky", top: 0 }}
            >
              <button className={styles.cross_btn} onClick={onClose}>
                &#10005;
              </button>
            </div>

            <div className={styles.form_container}>
              <div className={styles.formField}>
                <label>Company Name*</label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  placeholder="Enter Company Name"
                />
                {errors.company_name && (
                  <p style={{ color: "red", fontSize: "12.5px" }}>
                    {errors.company_name}
                  </p>
                )}
              </div>
              <div className={styles.formField}>
                <label>First Name*</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                />
                {errors.first_name && (
                  <p style={{ color: "red", fontSize: "12.5px" }}>
                    {errors.first_name}
                  </p>
                )}
              </div>
              <div className={styles.formField}>
                <label>Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                />
                {errors.last_name && (
                  <p style={{ color: "red", fontSize: "12.5px" }}>
                    {errors.last_name}
                  </p>
                )}
              </div>
              <div className={styles.formField}>
                <label>Email*</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email Id"
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "12.5px" }}>
                    {errors.email}
                  </p>
                )}
              </div>
              <div className={styles.formField}>
                <label>Phone*</label>
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  placeholder="Enter Phone Number"
                />
                {errors.number && (
                  <p style={{ color: "red", fontSize: "12.5px" }}>
                    {errors.number}
                  </p>
                )}
              </div>
              <button
                className={styles.submit_btn}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Submit and Proceed"}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* <CustomToast
        message={toast.message}
        type={toast.type}
        onClose={() => setToast({ message: "", type: "" })}
      /> */}
    </>
  );
};

export default EbookContactForm;
