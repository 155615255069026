import React, { useEffect } from "react";
import MainSection from "./components/MainSection";
import styles from "./supply_chain.module.css";
import InternalStakeholders from "./components/InternalStakeholders";
import ExternalStakeholders from "./components/ExternalStakeholders";
import { StyledHeading } from "../../HelperComponents";
import {
  FadeInComponent,
  SlideUpComponent,
  FadeOutComponent,
} from "../../AnimationConents";
import { AnimatePresence } from "framer-motion";

const SupplyChainNew = () => {
  const [active, setActive] = React.useState(0);

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.innerWidth < 450
  );

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 450);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [active]);

  // Conditional inline style for height when active === 0 and screen width < 450px
  const containerStyle = {
    height: active === 0 && isSmallScreen ? "600px" : "auto",
  };

  return (
    <AnimatePresence>
      <div className={styles["supply_chain_container"]} style={containerStyle}>
        <div className={styles.heading_box}>
          <StyledHeading text="The Supply Chain Super App" light="true" />
          <SlideUpComponent duration={1500}>
            <FadeOutComponent>
              <span className={styles.heading_para}>
                One platform for all your platforms and all your departments
              </span>
            </FadeOutComponent>
          </SlideUpComponent>
        </div>
        {active !== 0 ? (
          <FadeOutComponent duration={1500}>
            <div className={styles.go_back_btn} onClick={() => setActive(0)}>
              Go Back
            </div>
          </FadeOutComponent>
        ) : null}

        {active === 0 ? (
          <MainSection setActive={setActive} />
        ) : active === 1 ? (
          <InternalStakeholders />
        ) : (
          <FadeOutComponent duration={1500}>
            <ExternalStakeholders />
          </FadeOutComponent>
        )}
      </div>
    </AnimatePresence>
  );
};

export default SupplyChainNew;
