import React, { useEffect, useRef, useState } from "react";
import styles from "./subComponents.module.css";
import cloud from '../../../../assets/supplyChain/supply_chain_cloud.webp';
import { useTime, useTransform, motion } from "framer-motion";

const RoundObj = ({ items = ["Warehouse", "Agent", "Customer", "Transporter"], middleText = "Shipper", showGradient=true}) => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  const time = useTime();
  const rotate = useTransform(time, [0, 3000], [0, 360], {
    clamp: false,
  });
  const rotatingBg = useTransform(rotate, (r) => {
    return `linear-gradient(${r}deg,rgba(255, 119, 0, 0.79), transparent, transparent, rgba(255, 119, 0, 0.79))`
  })


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true); // Set visible when in view
        } else {
          setVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);



  return (
    <div className={styles["round_obj_container"]}>
      {/* gradient border */}

      {/* <div className={styles["radius"]}>
        <div className={styles["gradient-border-bg"]}></div>
      </div> */}

      {/* inner cylinder */}
      <div className={styles["cyl_container"]}>
        <div ref={ref} className={`${styles["cloud_box"]} ${visible ? styles["slide_up"] : ""}`}>
          <img src={cloud} />
        </div>
        <div className={styles["cylinder"]}>
          <div className={styles["bottom"]}></div>
          <div className={styles["middle"]}></div>
          <div className={styles["top"]}></div>
          <span className={styles["middle_text"]}>{middleText}</span>
        </div>
      </div>

      {/* filled border */}
      <motion.div 
        key="filled_border"
        className={styles["filled_border"]}
        exit={{y: 40, opacity: 0}}
        initial={{y: 40, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{duration: 0.6, delay: 0.4}}
      ></motion.div>
      <motion.div 
        key="node_container"
        className={styles["node_container"]}
        exit={{y: 70, opacity: 0}}
        initial={{y: 70, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{duration: 0.6, delay: 1}}
      >
        {items?.map((item, i) => {
          return (
            <div className={`${styles["text_node"]} ${styles[item?.toLowerCase()]}`} key={i}>
              <span style={{color:'#ff7700'}}>{item}</span>
              <div className={`${styles["node_connector"]} ${styles[item?.toLowerCase()]}`}></div>
            </div>
          )
        })}
      </motion.div>
      <motion.div 
        key="back_filled"
        className={styles["back_circle"]}
        initial={{y: 70, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: 70, opacity: 0}}
        transition={{duration: 0.6, delay: 0.8}}
      ></motion.div>
      <motion.div 
        key="gradient_anim"
        className={styles["grad_border"]} 
        style={{background: rotatingBg , display: showGradient ? "block" : "none"}}
        exit={{opacity: 0}}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.6, delay: 1.2}}
      ></motion.div>
    </div>
  )
};

export default RoundObj;