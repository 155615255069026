import React, { useState } from "react";
import "./Ebook.css";
import logistics from "../images/ebooklogo.png";
import ebook from "../images/decbg.png";
import EbookContactForm from "./EBook/EbookContactForm";
import MiddleCustomToast from "./CustomToast/MiddleCustomToast";

const Ebook = () => {
  const [openEbookForm, setOpenEbookForm] = useState(false);
  const [successToast, setSuccessToast] = useState({ message: "", type: "" });

  const handleOpenEbookForm = () => {
    setOpenEbookForm(true);
  };

  const showSuccessToastFn = (message, type) => {
    setSuccessToast({ message, type });
		setTimeout(() => setSuccessToast({ message: "", type: "" }), 5000);
  };

  const handleSuccessCloseFn = () => {
    setSuccessToast({ message: "", type: "" });
  };

  return (
    <div className="containerEbook">
      <div className="Ebookheading">E-book</div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="bookBox">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img className="bookBoxLogo" src={ebook} alt="" />
          </div>

          <div className="BookBoxItems">
            <img className="BookBOxInnerImg" src={logistics} alt="" />
            <span className="bookboxInnerhead" style={{ color: "#ff7700" }}>How to boost your warehouse operations?</span>
            <p className="bookboxInnerContent" style={{ color: "#ff7700" }}>
              Efficient warehouse management is the key to meeting growing consumer demands. Whether you're in e-commerce, D2C, manufacturing, or distribution, a well-optimized Warehouse
              Management System (WMS) ensures seamless operations, faster order fulfillment, and better resource utilization. Lynkgrid WMS is built to help businesses enhance efficiency,
              improve inventory accuracy, and scale effortlessly.
            </p>
            <div className="Clickme">
              <div className="knowmore_button1">
                <button onClick={handleOpenEbookForm} className="ebkbtn" >
                  Download now{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <EbookContactForm
          open={openEbookForm}
          setOpen={setOpenEbookForm}
          showSuccessToastFn={showSuccessToastFn}
					successToast={successToast}
        />
      </div>

			{successToast?.message ? (
				<div style={{height: "max-content", width: "max-content", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
					<MiddleCustomToast
						message={successToast.message}
						type={successToast.type}
						onClose={handleSuccessCloseFn}
					/>
				</div>
			) : null}
    </div>
  );
};

export default Ebook;