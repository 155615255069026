// import React, { useEffect, useState } from "react";
// import internal from "../../../assets/supplyChain/internal_stakeholder.webp";
// import internal_grp from "../../../assets/supplyChain/internal_group.svg";
// import styles from "./components.module.css";
// import cloud from "../../../assets/supplyChain/cloud.svg";
// import { FadeInComponent } from "../../../AnimationConents";
// import { motion } from "framer-motion";
// const InternalStakeholders = () => {
//   const [cloudAnimation, setCloudAnimation] = useState(false);
//   useEffect(()=>{
//     setCloudAnimation(true)
//   },[])
// 	return (
// 		// <div style={{width: "850px", perspective: "300px", display: "flex", alignItems: "center", justifyContent: "center"}}>
// 		//   <div className={styles["tank"]}>
// 		//     <div className={styles["bottom"]}></div>
// 		//     <div className={styles["middle"]}></div>
// 		//     <div className={styles["top"]}></div>
// 		//   </div>
// 		// </div>

// 		<>
// 			<FadeInComponent duration={1500}>
// 				<div className={styles.mslide3}>
// 					<div className={styles.consignor}>Internal</div>
// 					<div className={styles.cloud}>
// 						<img src={cloud} />
// 					</div>
// 					{/* <Carousel /> */}
// 				</div>
// 			</FadeInComponent>
// 			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
// 				<FadeInComponent duration={1500}>
// 					<div className={styles["internal_container"]}>
// 						{/* ------- INFO BOXES ------- */}
// 						<div className={styles.box_container}>
// 							{/* Container 1 */}
// 							<FadeInComponent duration={2000}>
// 								<div className={`${styles.container} ${styles.box1}`}>
// 									<div className={styles.section}>
// 										<h3>Finance Team</h3>
// 										<p>
// 											Vendor management, contract management, billing and invoicing, penalty and payment processing, lowering in bill processing time, reduction in logistics
// 											expenses.
// 										</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Control Tower Teams</h3>
// 										<p>IoT linkage, alarms and exceptions, calling and email integrations, ticketing.</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Marketing and Sales</h3>
// 										<p>Delivery management, order updates.</p>
// 									</div>
// 								</div>
// 							</FadeInComponent>
// 							<FadeInComponent duration={3000}>
// 								{/* Container 2 */}
// 								<div className={`${styles.container} ${styles.box2}`}>
// 									<div className={styles.section}>
// 										<h3>Logistics</h3>
// 										<p>Transporter management, indent processing tracking and updates, vehicle schedules and availability.</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Manufacturing and Production</h3>
// 										<p>Dispatch planning, incoming raw material visibility and production planning.</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Warehousing</h3>
// 										<p>Inventory management, linkage with trips and vehicles.</p>
// 									</div>
// 								</div>
// 							</FadeInComponent>
// 							<FadeInComponent duration={4000}>
// 								{/* Container 3 */}
// 								<div className={`${styles.container} ${styles.box3}`}>
// 									<div className={styles.section}>
// 										<h3>Loaders and Unloaders</h3>
// 										<p>Loading and unloading plans, digital packing lists, job and workflow automation, load optimisation and management.</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Security</h3>
// 										<p>Gate in and out management, inspection reports, driver and vehicle risk management and KYC.</p>
// 									</div>
// 									<div className={styles.divider}></div>
// 									<div className={styles.section}>
// 										<h3>Agents and Liners</h3>
// 										<p>Bill of lading and shipping bill and bill of entry integrations, vessel tracking in transit.</p>
// 									</div>
// 								</div>
// 							</FadeInComponent>
// 						</div>
// 						<img src={internal_grp} width="70%" height="50%" className={styles.internal_dimg} />
// 						{/* <img src={cloud} className={styles.internal_dcloudimg} /> */}
// 						<motion.div
// 							className={styles.internal_dcloudimg}
// 							initial={{ scale: 8, opacity: 1 }}
// 							animate={{
// 								scale: cloudAnimation ? 1 : 8, // Zoom effect
// 								opacity: cloudAnimation ? 1 : 0.5, // Fade out effect
// 							}}
// 							transition={{ duration: 1 }} // Duration of the zoom and fade
// 						>
// 							<img src={cloud} height="100%" width="100%" />
// 						</motion.div>
// 					</div>
// 				</FadeInComponent>
// 			</div>
// 		</>
// 	);
// };

// export default InternalStakeholders;

import React, { useEffect, useState } from "react";
import internal from "../../../assets/supplyChain/internal_stakeholder.webp";
import internal_grp from "../../../assets/supplyChain/internal_group.svg";
import styles from "./components.module.css";
import cloud from "../../../assets/supplyChain/cloud.svg";
import { FadeInComponent } from "../../../AnimationConents";
import { motion } from "framer-motion";
import internal_arrow from "../../../assets/supplyChain/internal_arrow.svg";
// Stagger animation for containers
const staggerContainerAnimation = {
	hidden: { opacity: 0, y: 20 },
	show: {
		opacity: 1,
		y: 0,
		transition: {
			staggerChildren: 0.5, // 1 second delay between each container (box1, box2, box3)
		},
	},
};

// Animation for individual sections inside each container
const sectionAnimation = {
	hidden: { opacity: 0, y: 20 },
	show: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 1, // Duration of each section animation
		},
	},
};
const InternalStakeholders = ({ setActive }) => {
	const [scaleUp, setScaleUp] = useState(false); // State for scale-up transition
	const [cloudAnimation, setCloudAnimation] = useState(false);

	// Trigger scaling when setActive is called
	useEffect(() => {
		if (setActive) {
			setScaleUp(true); // Trigger scale-up when setActive is called
		}
	}, [setActive]);

	// Trigger cloud animation on mount
	useEffect(() => {
		setCloudAnimation(true);
	}, []);

	return (
		<>
			<FadeInComponent duration={1500}>
				<div className={styles.mslide3}>
					<div className={styles.consignor}>Internal</div>
					<div className={styles.cloud}>
						<img src={cloud} />
					</div>
					{/* <Carousel /> */}
				</div>
			</FadeInComponent>

			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className={styles.main_box_container}>
				{/* <FadeInComponent duration={1500}> */}
					<motion.div
						className={styles.internal_container}
						// initial={{ scale: 0 }} // Start scale at 0
						// animate={{ scale: scaleUp ? 1 : 1 }} // Scale up when setActive triggers
						// transition={{ duration: 1, ease: "easeInOut" }} // Smooth scale-up transition
					>
						{/* ------- INFO BOXES ------- */}
						{/* <div className={styles.box_container}> */}
							{/* Container 1 */}
							{/* <FadeInComponent duration={2000}>
                <div className={`${styles.container} ${styles.box1}`}>
                  <div className={styles.section}>
                    <h3>Finance Team</h3>
                    <p>
                      Vendor management, contract management, billing and invoicing, penalty and payment processing, lowering in bill processing time, reduction in logistics expenses.
                    </p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Control Tower Teams</h3>
                    <p>IoT linkage, alarms and exceptions, calling and email integrations, ticketing.</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Marketing and Sales</h3>
                    <p>Delivery management, order updates.</p>
                  </div>
                </div>
              </FadeInComponent> */}
							{/* <FadeInComponent duration={3000}>
                
                <div className={`${styles.container} ${styles.box2}`}>
                  <div className={styles.section}>
                    <h3>Logistics</h3>
                    <p>Transporter management, indent processing tracking and updates, vehicle schedules and availability.</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Manufacturing and Production</h3>
                    <p>Dispatch planning, incoming raw material visibility and production planning.</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Warehousing</h3>
                    <p>Inventory management, linkage with trips and vehicles.</p>
                  </div>
                </div>
              </FadeInComponent> */}
							{/* <FadeInComponent duration={4000}>
             
                <div className={`${styles.container} ${styles.box3}`}>
                  <div className={styles.section}>
                    <h3>Loaders and Unloaders</h3>
                    <p>Loading and unloading plans, digital packing lists, job and workflow automation, load optimisation and management.</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Security</h3>
                    <p>Gate in and out management, inspection reports, driver and vehicle risk management and KYC.</p>
                  </div>
                  <div className={styles.divider}></div>
                  <div className={styles.section}>
                    <h3>Agents and Liners</h3>
                    <p>Bill of lading and shipping bill and bill of entry integrations, vessel tracking in transit.</p>
                  </div>
                </div>
              </FadeInComponent> */}
						{/* </div> */}
						<motion.div className={styles.box_container} initial="hidden" animate="show" variants={staggerContainerAnimation}>
							{/* Container 1 */}
							<motion.div
								className={`${styles.container} ${styles.box1}`}
								variants={staggerContainerAnimation} // Apply stagger effect to the container
							>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Finance Team</h3>
									<p>
										Vendor management, contract management, billing and invoicing, penalty and payment processing, lowering in bill processing time, reduction in logistics
										expenses.
									</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Control Tower Teams</h3>
									<p>IoT linkage, alarms and exceptions, calling and email integrations, ticketing.</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Marketing and Sales</h3>
									<p>Delivery management, order updates.</p>
								</motion.div>
							</motion.div>

							{/* Container 2 */}
							<motion.div
								className={`${styles.container} ${styles.box2}`}
								variants={staggerContainerAnimation} // Apply stagger effect to the container
							>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Logistics</h3>
									<p>Transporter management, indent processing tracking and updates, vehicle schedules and availability.</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Manufacturing and Production</h3>
									<p>Dispatch planning, incoming raw material visibility and production planning.</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Warehousing</h3>
									<p>Inventory management, linkage with trips and vehicles.</p>
								</motion.div>
							</motion.div>

							{/* Container 3 */}
							<motion.div
								className={`${styles.container} ${styles.box3}`}
								variants={staggerContainerAnimation} // Apply stagger effect to the container
							>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Loaders and Unloaders</h3>
									<p>Loading and unloading plans, digital packing lists, job and workflow automation, load optimisation and management.</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Security</h3>
									<p>Gate in and out management, inspection reports, driver and vehicle risk management and KYC.</p>
								</motion.div>
								<div className={styles.divider}></div>
								<motion.div className={styles.section} variants={sectionAnimation}>
									<h3>Agents and Liners</h3>
									<p>Bill of lading and shipping bill and bill of entry integrations, vessel tracking in transit.</p>
								</motion.div>
							</motion.div>
						</motion.div>
						<img src={internal_arrow} width="70%" height="50%" className={styles.internal_dimg} />

						{/* Cloud Image without Animation */}
						{/* <div className={styles.internal_dcloudimg}>
              <img src={cloud} height="100%" width="100%" />
            </div> */}
					</motion.div>
				{/* </FadeInComponent> */}
			</div>
		</>
	);
};

export default InternalStakeholders;
