import React from "react";
import mainStyle from "../main.module.css";
import Header from "./Components/Header/Header";
import API from './Components/API';
import Ticker from "./Components/Ticker";
import Testimonial from "./Components/Testimonials";
import Features from "./Components/Features";
// import Reviews from "./Components/Reviews";
import OtherFeature from "./Components/OtherFeature";
import QuickGlance from "./Components/QuickGlance";
import Industries from "./Components/Industries";
import OurAwards from "./Components/OurAwards";
import LatestNews from "./Components/LatestNews";
import Linkedin from "./Components/Linkedin";
import Footer from "./Components/Footer";
import Heros from './Components/Heros'
// import SupplyChain from "./Components/SupplyChain";
import Hardware from "./Components/Hardware";
import SupplyChainNew from "./Components/SupplyChainNew";
import News from "./Components/News";
const LynkitIOLandingPage = () => {
  const [selectedFeature, setSelectedFeature] = React.useState(0);
  const [selectedIndustry, setSelectedIndustry] = React.useState(0);

  return (
    <div className={mainStyle.landing_page} style={{overflowX: "hidden"}}>
      <Header setSelectedFeature={setSelectedFeature} />
      <div
        className={mainStyle.section_wrapper}
        style={{ position: "relative" }}
      >
        
        <section
          className={mainStyle.section}
          style={{position: "relative" }}
        >
          <Heros />
        </section>

   

           <section
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <Ticker />
        </section>

        <section
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <QuickGlance />
        </section>


        <section
          id="features"
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <Features setCurrent={setSelectedFeature} current={selectedFeature} />
        </section>
        <section
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <Testimonial />
        </section>

        {/* <section
          className={mainStyle.section}
          style={{position:'relative'}}
          id="useCases"
        >
          <SupplyChain/>
        </section> */}

        <section
          className={mainStyle.section}
          style={{position:'relative', minHeight: "auto"}}
          id="useCases"
        >
          <SupplyChainNew/>
        </section>

        {/* <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Reviews />
        </section> */}
  
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <OtherFeature setCurrent={setSelectedFeature} current={selectedFeature} />
        </section>

        <section
          id="hardware-products"
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Hardware />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
          id="api"
        >
          <API />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Industries setCurrent={setSelectedIndustry} current={selectedIndustry} />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <OurAwards />
        </section>
        {/* <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <LatestNews />
        </section> */}
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <News />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Linkedin />
        </section>

      </div>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Footer />
        </section>
    </div>
  );
};

export default LynkitIOLandingPage;
