import React, { useState } from "react";
import styles from "./styles.module.css";
import Title from "../../helper_components/title";
import BodyText from "../../helper_components/body_text";
import earthMoving from "../../assets/lane/earth_vid.mp4";
import earthMask from "../../assets/lane/earth_mask_updated.webp";
import { useInView, useAnimation } from "framer-motion";
import { ReactComponent as CheckIcon } from "../../assets/lane/tick_orange.svg";
import mb_bg from "../../assets/lane/mb_bg.svg";
import cargoShip from "../../assets/shippers/cargoship.gif";
import freight from "../../assets/shippers/container.gif";
import planeOrange from "../../assets/lane/planeOrange.svg";
import planeWhite from "../../assets/lane/planeWhite.svg";
import shipOrange from "../../assets/lane/ShipOrange.svg";
import shipWhite from "../../assets/lane/shipWhite.svg";
import bothOrange from "../../assets/lane/bothOrange.svg";
import bothWhite from "../../assets/lane/bothWhite.svg";
import closeIcon from "../../assets/lane/closeIcon.svg";
import priceTick from "../../assets/lane/price_tick.svg";
import priceCross from "../../assets/lane/price_cross.svg";
import { motion } from "framer-motion";
import { activeUrl } from "../../config";
import useApi from "../../useApi";
import CustomToast from "../../../../CustomToast";

export const Shippers = ({ onViewPlansClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.flexContainer}>
        <div className={styles.flexContainerLeftPart}>
          <div className={styles.upperbox_allSide_border}>
            <div className={styles["border-container"]}>
              <div className={styles["inner-border"]}>
                <img src={cargoShip} />
              </div>
              <div className={styles["outer-border"]}></div>

              <div className={`${styles.corner} ${styles["top-left"]}`}></div>
              <div className={`${styles.corner} ${styles["top-right"]}`}></div>
              <div
                className={`${styles.corner} ${styles["bottom-left"]}`}
              ></div>
              <div
                className={`${styles.corner} ${styles["bottom-right"]}`}
              ></div>

              <div className={styles.connecting_lines}>
                <div className={styles.bottom_left_to_top_right}></div>
                <div className={styles.bottom_right_to_top_left}></div>
              </div>
            </div>
          </div>

          <div className={styles.leftContent}>
            <div className={styles.leftContentHead}>Shippers</div>
            <div className={styles.leftContentpara}>
              1 year license for 1 to 20 lanes 3 month lock in per lane
            </div>
          </div>
          <div className={styles.action_btn_container}>
            <button
              className={styles.filled_orange}
              onClick={() => {
                onViewPlansClick("shipper");
              }}
            >
              View Plans
            </button>
          </div>
        </div>

        <div className={styles.straightLine}></div>
        <div className={styles.horizontalLine}></div>

        <div className={styles.flexContainerRighttPart}>
          <div className={styles.lowerbox_allSide_border}>
            <div className={styles["border-container"]}>
              <div className={styles["inner-border"]}>
                <img src={freight} />
              </div>
              <div className={styles["outer-border"]}></div>

              <div className={`${styles.corner} ${styles["top-left"]}`}></div>
              <div className={`${styles.corner} ${styles["top-right"]}`}></div>
              <div
                className={`${styles.corner} ${styles["bottom-left"]}`}
              ></div>
              <div
                className={`${styles.corner} ${styles["bottom-right"]}`}
              ></div>

              <div className={styles.connecting_lines}>
                <div className={styles.bottom_left_to_top_right}></div>
                <div className={styles.bottom_right_to_top_left}></div>
              </div>
            </div>
          </div>

          <div className={styles.leftContent}>
            <div className={styles.leftContentHead}>Freight Forwarders</div>
            <div className={styles.leftContentpara}>
              1 year license for 21 to 60 lanes 3 month lock in per lane
            </div>
          </div>
          <div className={styles.action_btn_container}>
            <button
              className={styles.filled_orange}
              onClick={() => {
                onViewPlansClick("freightforwarder");
              }}
            >
              View Plans
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeaturesTable = ({ userType, onSelectPlan }) => {
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const features = React.useMemo(() => {
    if(userType === "shipper"){
      return [
        {
          id: 1,
          name: "Lanes Change option",
          values: [
            "3 Month locking period",
            "3 Month locking period",
            "3 Month locking period",
            "3 Month locking period",
          ],
        },
        {
          id: 2,
          name: "Graph Filter ( Market price: High, Mid, High, Average, Mid-low, Low ), Contract type ( Long or short), Container Type, THC ( Origin, Destination, Both, None)",
          values: ["All", "All", "All", "All"],
        },
        {
          id: 3,
          name: "Graph Download (Image, Excel)",
          values: [false, true, true, true],
        },
        { id: 4, name: "Highlights", values: [false, true, true, true] },
        { id: 5, name: "Blogs", values: [false, true, true, true] },
        // {
        //   id: 6,
        //   name: "Spot Credit - Credit Wallet - One Filter one Credit Use",
        //   values: [false, "Extra Purchase - 5 credit free per 5 lanes", "Extra Purchase - 10 credit free per 5 lanes", "Extra Purchase - 15 credit free per 5 lanes"],
        // },
        { id: 7, name: "Candle View", values: [false, true, true, true] },
        {
          id: 8,
          name: "Future data visbilty +10 days",
          values: [false, false, true, true],
        },
        { id: 9, name: "Add buying price", values: [false, false, true, true] },
        { id: 14, name: "Carrier Spread & Filters", values: [false, false, true, true] },
        { id: 10, name: "Multi user login", values: [false, false, false, true] },
        { id: 11, name: "RFQ integration", values: [false, false, false, true] },
        { id: 15, name: "Currency Index Rates", values: [false, false, false, true] },
        { id: 16, name: "Commodity Index Rates", values: [false, false, false, true] },
        {
          id: 6,
          name: "Spot Credit - Credit Wallet - One Filter one Credit Use",
          values: [false, "Extra Purchase - 5 credit free per 5 lanes", "Extra Purchase - 10 credit free per 5 lanes", "Extra Purchase - 15 credit free per 5 lanes"],
        },
        { id: 12, name: "Training session", values: ["Single", "2 sessions", "3 sessions", "4 sessions"] },
      ]
    }else{
      return [
        {
          id: 1,
          name: "Lanes Change option",
          values: [
            "3 Month locking period",
            "3 Month locking period",
            "3 Month locking period",
            "3 Month locking period",
          ],
        },
        {
          id: 2,
          name: "Graph Filter ( Market price: High, Mid, High, Average, Mid-low, Low ), Contract type ( Long or short), Container Type, THC ( Origin, Destination, Both, None)",
          values: ["All", "All", "All", "All"],
        },
        {
          id: 3,
          name: "Graph Download (Image, Excel)",
          values: [false, true, true, true],
        },
        { id: 4, name: "Highlights", values: [false, true, true, true] },
        { id: 5, name: "Blogs", values: [false, true, true, true] },
        // {
        //   id: 6,
        //   name: "Spot Credit - Credit Wallet - One Filter one Credit Use",
        //   values: [false, "Extra Purchase - 5 credit free per 5 lanes", "Extra Purchase - 10 credit free per 5 lanes", "Extra Purchase - 15 credit free per 5 lanes"],
        // },
        { id: 7, name: "Candle View", values: [false, true, true, true] },
        {
          id: 8,
          name: "Future data visbilty +10 days",
          values: [false, false, true, true],
        },
        { id: 9, name: "Add buying price", values: [false, false, true, true] },
        { id: 14, name: "Carrier Spread & Filters", values: [false, false, true, true] },
        { id: 10, name: "Multi user login", values: [false, false, false, true] },
        { id: 11, name: "RFQ integration", values: [false, false, false, true] },
        { id: 15, name: "Currency Index Rates", values: [false, false, false, true] },
        { id: 16, name: "Commodity Index Rates", values: [false, false, false, true] },
        {
          id: 6,
          name: "Spot Credit - Credit Wallet - One Filter one Credit Use",
          values: [false, "Extra Purchase - 5 credit free per 5 lanes", "Extra Purchase - 10 credit free per 5 lanes", "Extra Purchase - 15 credit free per 5 lanes"],
        },
        { id: 12, name: "Training session", values: ["Single", "2 sessions", "3 sessions", "4 sessions"] },
      ]
    }
  })

  const handleMouseEnter = (index) => {
    setHoveredColumn(index);
  };

  const handleMouseLeave = () => {
    setHoveredColumn(null);
  };

  const headers = ["Basic", "Standard", "Premium", "Enterprise"];

  return (
    <div className={styles.table_container}>
      <div className={styles.table}>
        {/* Header */}
        <div className={styles.headerRow}>
          {/* <div className={styles.cell} style={{maxWidth: "6%"}}>No</div> */}
          <div className={styles.cell} style={{ minWidth: "200px" }}>
            Features
          </div>
          {headers.map((header, index) => (
            <div className={`${styles.cell}`}>
              <button
                key={index}
                className={`${styles.cell_btn} ${
                  hoveredColumn === index ? styles.highlight_btn : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                disabled={header === "Premium" || header === "Enterprise"}
                onClick={() => onSelectPlan(header?.toLowerCase())}
              >
                {header}
                {header === "Premium" || header === "Enterprise" ? (
                  <div className={styles.tooltip}>Coming Soon</div>
                ) : null}
              </button>
            </div>
          ))}
        </div>

        {/* Features Rows */}
        {features.map((feature, rowIndex) => (
          <div key={rowIndex} className={styles.featureRow}>
            {/* <div className={styles.cell} style={{maxWidth: "6%"}}>{feature.id}</div> */}
            <div className={styles.cell} style={{ minWidth: "200px" }}>
              {feature.name}
            </div>
            {feature.values.map((value, colIndex) => (
              <div
                key={colIndex}
                className={`${styles.cell} ${
                  hoveredColumn === colIndex ? styles.highlight : ""
                }`}
                style={{cursor: "pointer"}}
                onMouseEnter={() => handleMouseEnter(colIndex)}
                onMouseLeave={handleMouseLeave}
                disabled={headers[colIndex] === "Premium" || headers[colIndex] === "Enterprise"}
                onClick={() => onSelectPlan(headers[colIndex]?.toLowerCase())}
              >
                {typeof value === "boolean" ? <img src={value ? priceTick : priceCross} height="15px" width="15px" /> : value}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Lane = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [earlyAccessModalOpen, setEarlyAccessModalOpen] = useState(false);

  const [userType, setUserType] = useState(""); // "shipper" or "freightforwarder"
  const [planType, setPlanType] = useState(""); // "basic" or "standard"
  const [mode, setMode] = useState("air"); // "ocean", "air", or "both"
  const [selectedLane, setSelectedLane] = useState(5); // Number of lanes (5, 10, 20, etc.)
  const [price, setPrice] = useState(""); // Final calculated price
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [toast, setToast] = useState({ message: "", type: "" });
  const { callApi, loading } = useApi((message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast({ message: "", type: "" }), 5000);
  });

  const container = React.useRef(null);
  const isInView = useInView(container, {
    margin: "-100px 0px -100px 0px",
    once: true,
  });
  const controls = useAnimation();

  const numberOfLanes = React.useMemo(() => {
    if (userType) {
      if (userType === "shipper") {
        setSelectedLane(5);
        return [5, 10, 20];
      } else {
        setSelectedLane(20);
        return [20, 40, 60];
      }
    } else {
      return [];
    }
  }, [userType]);

  React.useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  const cardVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        delay: index * 0.8,
      },
    }),
    out: { opacity: 0, y: -100, transition: { duration: 0.2 } },
  };

  const cards = [
    {
      title: "Basic",
      id: "basic",
      list: [
        "Lane",
        "Graph Filter",
        "Graph Download",
        "Spot Credit",
        "Spot Price Check",
      ],
    },
    {
      title: "Standard",
      id: "standard",
      list: [
        "Lane",
        "Graph Filter + Container Type",
        "Graph Download",
        "Blogs",
        "Spot Credit",
        "Candle View",
        "Spot Price Check",
      ],
    },
    {
      title: "Premium",
      id: "premium",
      list: [
        "Lane",
        "Graph Filter + Container Type + Contract Type + THC",
        "Graph Download",
        "Highlights",
        "Blogs",
        "Future data visbilty +10 days & +3M",
        "Spot Credit",
        "Candle View",
        "Spot Price Check",
      ],
    },
    {
      title: "Enterprise",
      id: "enterprise",
      list: [
        "Lane",
        "Graph Filter + Container Type + Contract Type + THC",
        "Graph Download",
        "Highlights",
        "Blogs",
        "Future data visbilty +10 days & +3M",
        "Spot Credit",
        "Candle View",
        "Spot Price Check",
      ],
    },
  ];

  const modelData = [
    {
      text: "Air Freight",
      id: "air",
      icon: planeOrange,
      whiteIcon: planeWhite,
    },
    {
      text: "Ocean Freight",
      id: "ocean",
      icon: shipOrange,
      whiteIcon: shipWhite,
    },
    {
      text: "Both",
      id: "both",
      icon: bothOrange,
      whiteIcon: bothWhite,
    },
  ];

  const formatToINR = (number) => {
    return new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0, // Adjust for decimals if needed
    }).format(number);
  };

  const handleViewPlansClick = (option) => {
    setUserType(option);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const openEarlyAccessModal = (type) => {
    setFormData({
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
    });
    setPrice("");
    setErrors({});
    if (type === "basic" || type === "standard") {
      setPlanType(type);
      setEarlyAccessModalOpen(true);
    } else {
      return;
    }
  };

  const closeEarlyAccessModal = () => {
    setEarlyAccessModalOpen(false);
  };

  const handleLaneChange = (lane) => {
    setPrice("");
    setSelectedLane(lane);
  };

  const handleModeChange = (mode) => {
    setPrice("");
    setMode(mode);
  };

  const validate = () => {
    const newErrors = {};

    // Validate Name
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company Name is required.";
    }

    // Validate First Name
    const alphabetRegex = /^[a-zA-Z ]+$/;
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!alphabetRegex.test(formData.firstName)) {
      newErrors.firstName = "Enter a valid first name.";
    }

    // Validate Last Name - optional field
    if (formData.lastName.trim() && !alphabetRegex.test(formData.lastName)) {
      newErrors.lastName = "Enter a valid last name.";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    // Validate mobile
    const mobileRegex = /^\d{10}$/;
    if (!formData.mobile) {
      newErrors.mobile = "Phone Number is required.";
    } else if (!mobileRegex.test(formData.mobile)) {
      newErrors.mobile = "Enter a valid phone number.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userType || !planType || !mode || !selectedLane) {
      alert("Please select all options.");
      return;
    }

    if (validate()) {
      const payload = {
        ...formData,
        laneType: mode, // possible values are- "ocean" or "air" or "both"
        noOfLanes: selectedLane, // possible values are- for shipper:  5, 10, 20; for freightforwarder: 20, 40, 60
        planType: planType, // possible values are- 'basic', 'standard', 'premium', or 'enterprise'
        userType: userType, // possible values are- 'shipper', or 'freightforwarder'
      };

      const response = await callApi({
        url: `${activeUrl}/price/getPriceDetailsWithoutAuth`,
        method: "POST",
        data: payload,
      });
      if (response.success) {
        setPrice(response?.data?.price);
      } else {
        setPrice("");
      }
      // Submit form data (e.g., API call)
    } else {
      setPrice("");
    }
  };

  const handleChange = (e) => {
    setPrice("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  return (
    <div className={styles.lane_container}>
      <div className={styles.top_section}>
        <div className={styles.text_box}>
          <Title text="What Is a @Lane?" />
          <BodyText text="Lane refers to a directional combination between any two sea or air ports. Mundra to Antwerp would be considered a single lane, and Antwerp to Mundra another lane." />
        </div>
        <div className={styles.video_bg}>
          <video src={earthMoving} autoPlay loop muted playsInline />
          <img src={earthMask} className={styles.img_mask} />
          <img src={mb_bg} className={styles.mb_bg} />
        </div>
      </div>

      <Shippers
        onViewPlansClick={(type) => {
          setPrice("");
          handleViewPlansClick(type);
        }}
      />

      {modalOpen && (
        <motion.div
          className={styles.modal}
          onClick={handleCloseModal}
          initial={{ opacity: 0 }} // Initial state (hidden)
          animate={{ opacity: 1 }} // Animate to visible
          exit={{ opacity: 0 }} // Exit state (hidden)
          transition={{ duration: 0.9 }} // Duration of fade-in/fade-out animation
        >
          <motion.div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: "100%", opacity: 0 }} // Start off below the screen
            animate={{ y: 0, opacity: 1 }} // Move to its original position and fade in
            exit={{ y: "100%", opacity: 0 }} // Slide down when exiting
            transition={{ type: "spring", stiffness: 300, damping: 20 }} // Spring effect for smoother animation
          >
            <div className={styles.modalHeader}>
              {/* <Title text={"Plans Available"} /> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className={styles.modal_title}>
                  {userType === "shipper" ? "Shippers" : "Freight forwarders"}
                </span>
              </div>
              <button
                className={styles.closeModalBtn}
                onClick={handleCloseModal}
              >
                <img src={closeIcon} alt="" />
              </button>
            </div>
            <div className={styles.cards_section}>
              <div className={styles.cards_wrapper}>
                {cards?.map((item, idx) => (
                  <div className={styles.card} key={idx}>
                    <div className={styles.card_title}>
                      <Title
                        text={item.title}
                        extraStyles={{
                          fontFamily: "'Fjalla One', sans-serif",
                          fontWeight: "500",
                          fontSize: "2rem",
                        }}
                      />
                    </div>
                    <div className={styles.card_divider} />
                    <div>
                      <button
                        className={styles.card_button}
                        style={{ position: "relative", zIndex: "2" }}
                        onClick={() => {
                          openEarlyAccessModal(item?.id);
                        }}
                      >
                        {item?.id === "basic" || item?.id === "standard"
                          ? "Get Early Access"
                          : "Coming Soon"}
                      </button>
                    </div>
                    <div className={styles.list_container}>
                      {item?.list?.map((list, i) => (
                        <div className={styles.list_item} key={i}>
                          <div className={styles.check_icon}>
                            <CheckIcon />
                          </div>
                          <span>{list}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.features_desktop}>
                <FeaturesTable userType={userType} onSelectPlan={openEarlyAccessModal} />
              </div>

              {earlyAccessModalOpen && (
                <>
                  <motion.div
                    className={styles.modalSecond}
                    onClick={closeEarlyAccessModal}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <motion.div
                      className={styles.modalContentSecond}
                      onClick={(e) => e.stopPropagation()}
                      initial={{ scale: 0.8, opacity: 0 }} // Start off scaled down and invisible
                      animate={{ scale: 1, opacity: 1 }} // Animate to full size and visible
                      exit={{ scale: 0.8, opacity: 0 }} // Shrink when exiting
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                    >
                      <div className={styles.modalSecond}>
                        <div
                          className={styles.modalContentSecond}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* Header */}
                          <div className={styles.modalHeader}>
                            <div className={styles.modalSecondHead}>
                              {userType === "shipper"
                                ? "Shippers"
                                : "Freight Forwarders"}
                            </div>
                            <button
                              className={styles.closeModalBtn}
                              onClick={() => {
                                setMode("air");
                                setEarlyAccessModalOpen(false);
                              }}
                            >
                              <img src={closeIcon} alt="Close" />
                            </button>
                          </div>
                          <div className={styles.horizontalLine1}></div>

                          <div className={styles.contact_form}>
                            <div className={styles.contact_form_row}>
                              <div className={styles.formField}>
                                <label>Company Name*</label>
                                <input
                                  type="text"
                                  name="companyName"
                                  value={formData.companyName}
                                  onChange={handleChange}
                                  placeholder='Enter Company Name'
                                />
                                {errors.companyName && (
                                  <p style={{ color: "red", fontSize: "12.5px" }}>
                                    {errors.companyName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className={styles.contact_form_row}>
                              <div className={styles.formField}>
                                <label>First Name*</label>
                                <input
                                  type="text"
                                  name="firstName"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  placeholder='Enter First Name'
                                />
                                {errors.firstName && (
                                  <p style={{ color: "red", fontSize: "12.5px" }}>
                                    {errors.firstName}
                                  </p>
                                )}
                              </div>
                              <div className={styles.formField}>
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  name="lastName"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  placeholder='Enter Last Name'
                                />
                                {errors.lastName && (
                                  <p style={{ color: "red", fontSize: "12.5px" }}>
                                    {errors.lastName}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className={styles.contact_form_row}>
                              <div className={styles.formField}>
                                <label>Email*</label>
                                <input
                                  type="text"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  placeholder='Enter Email Id'
                                />
                                {errors.email && (
                                  <p style={{ color: "red", fontSize: "12.5px" }}>{errors.email}</p>
                                )}
                              </div>
                              <div className={styles.formField}>
                                <label>Phone*</label>
                                <input
                                  type="text"
                                  name="mobile"
                                  value={formData.mobile}
                                  onChange={handleChange}
                                  placeholder='Enter Phone Number'
                                />
                                {errors.mobile && (
                                  <p style={{ color: "red", fontSize: "12.5px" }}>
                                    {errors.mobile}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Body - Modes */}
                          <div className={styles.modalBody}>
                            {modelData.map((item, idx) => (
                              <button
                                key={idx}
                                className={`${styles.mode_btn} ${
                                  mode === item.id
                                    ? styles.selectedMode
                                    : styles.unselectedMode
                                }`}
                                onClick={() => handleModeChange(item.id)}
                              >
                                {mode === item.id ? (
                                  <img src={item.whiteIcon} style={{ height: "25px", width: "25px", marginRight: "15px" }} />
                                ) : (
                                  <img
                                    src={item.icon}
                                    style={{ height: "25px", width: "25px", marginRight: "15px" }}
                                  />
                                )}

                                <span>{item.text}</span>
                              </button>
                            ))}
                          </div>

                          {/* Lane Selection */}
                          <div
                            // className={styles.modalSecondHead}
                            id={styles.modalSecondHeadID}
                          >
                            Select the number of lanes
                          </div>
                          <div className={styles.horizontalLine1}></div>
                          <div className={styles.radioOption}>
                            {numberOfLanes.map((lane) => (
                              <label
                                key={lane}
                                className={styles.allRadioOption}
                              >
                                <input
                                  type="radio"
                                  name="lanes"
                                  value={lane}
                                  checked={selectedLane === lane}
                                  onChange={() => handleLaneChange(lane)}
                                />
                                {lane}
                              </label>
                            ))}
                          </div>

                          {/* Get Price Button */}
                          {price ? (
                            <div className={styles.price_container}>
                              <span
                                className={styles.price}
                              >{`Rs. ${formatToINR(price)}`}</span>
                            </div>
                          ) : (
                            <button
                              className={styles.getPriceBtn}
                              style={{ marginTop: "20px" }}
                              onClick={handleSubmit}
                              disabled={loading}
                            >
                              {loading ? "Loading..." : "Get Price"}
                            </button>
                          )}
                        </div>
                      </div>
                    </motion.div>
                  </motion.div>
                </>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
      <CustomToast
        message={toast.message}
        type={toast.type}
        onClose={() => setToast({ message: "", type: "" })}
      />
    </div>
  );
};

export default Lane;
