import React, { useState } from "react";
import styles from "./styles.module.css";
import img from "../../assets/pexels-hu-nh-an-3895313-6778692.jpg";
import quoteStart from "../../assets/quote_start_icon.svg";
import quoteEnd from "../../assets/quote_end_icon.svg";
import back_active_btn from "../../assets/back_btn_active.svg";
import back_disabled_btn from "../../assets/back_btn_disabled.svg";
import next_active_btn from "../../assets/next_btn_active.svg";
import next_disabled_btn from "../../assets/next_btn_disabled.svg";
import flipkart_logo from "../../assets/testimonial/flipkart_logo.svg";
import flipkart_main from "../../assets/testimonial/flipkart_main.svg";
import samsung_logo from "../../assets/testimonial/samsung_logo.svg";
import samsung_main from "../../assets/testimonial/samsung_main.svg";
import apml_logo from "../../assets/testimonial/apml_logo.svg";
import apml_main from "../../assets/testimonial/apml_main.svg";
import landmark from "../../assets/testimonial/landmark.png";
import landmark_main from "../../assets/testimonial/landmark_main.svg";
import cma_gsm_logo from "../../assets/testimonial/cma_gsm_logo.svg";
import cma_gsm_main from "../../assets/testimonial/cma_gsm_main.svg";
import bpcl_logo from "../../assets/testimonial/bpcl_logo.svg";
import bpcl_main from "../../assets/testimonial/bpcl_main.svg";
import haierIcon from '../../assets/testimonial/hairIcon.png';
import haierImg from '../../assets/testimonial/haierImg.svg'

const slides = [
  {
    quote:
      "Using Lynkit's TMS we have been able to save 4 hours per man per day reducing manual paper work",
    author: "Assistant Manager-Operations",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },
      { number: 4, description: "Hours Per Man Per Day Saved" },
    ],
    logo: cma_gsm_logo,
    img: cma_gsm_main,
  },
  {
    quote:
      "Lynkit's supplier network application have helped us greatly decrease our production and logistics times and improve our JIT manufacturing processes with safer and secure deliveries.",
    author: "General Manager",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },
      { number: '35%', description: " Enhanced JIT Manufacturing Processes" }
    ],
    logo: samsung_logo,
    img: samsung_main,
  },
  {
    quote:
      "Some of the age old issues in LPG ecosystem like enroute pilferage, visibility to customer on likely delivery time and choosing their own time for refill delivery are getting addressed. For our distributors, it offers services like AI based route optimiser, which would enhance its delivery efficiencies.",
    author: "Marketing Director",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },

    ],
    logo: bpcl_logo,
    img: bpcl_main,
  },
  {
    quote:
      "Lynkit's vehicle management solution has been transformative for our operations. The increased transparency of vehicle availability at bays has significantly enhanced our efficiency. The streamlined process has led to a 50% reduction in turnaround time (TAT), boosting productivity and customer satisfaction. Lynkit has proven to be an invaluable asset to our business.",
    author: "Head - IT",
    metrics: [
      { number: '50%', description: "Reduction In Turnaround Time(TAT)" },

    ],
    logo: haierIcon,
    img: haierImg,
  },
  {
    quote:
      "We have been using Lynkit's GPS locks for over a year now, and have been able to regularly prevent 2-3 thefts/hijackings of our vehicles per month.",
    author: "Assistant Manager",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },
      { number: 3, description: "Thefts And Hijackings Prevented In A Month" }
    ],
    logo: flipkart_logo,
    img: flipkart_main,
  },

  {
    quote:
      "Lynkit's WMS has helped us manage our customer's shipments with great accuracy and speed, we are happy with the system and flexible approach they had.",
    author: "Manager Operations",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },
      { number: '99.99%', description: "Inventory Accuracy" },

    ],
    logo: apml_logo,
    img: apml_main,
  },
  {
    quote:
      "Lynkit's Container Location system has brought us huge efficiencies. We have been able to find the container with high accuracy and gate-in / out has become smooth and faster.",
    author: "Managing Director",
    metrics: [
      // { number: 1, description: "Deliver key metrics to senior leadership" },
      // { number: 10, description: "Visibility into critical data" },

    ],
    logo: landmark,
    img: landmark_main,
  },



];

const MetricCard = ({ count, text }) => {
  return (
    <div className={styles.metrics_box_container}>
      <div className={styles["metric_count_box"]}>{count}</div>
      <div className={styles["metric_text"]}>
        <span>{text}</span>
      </div>
    </div>
  );
};

const Slider = () => {
  const [current, setCurrent] = useState(0);

  const handleButtonControl = (option) => {
    if (option === "back") {
      setCurrent(current - 1);
    } else {
      setCurrent(current + 1);
    }
  };

  const handleThumbSlideChange = (index) => {
    setCurrent(index);
  };

  return (
    <>
      <div className={`${styles.slider_container} ${styles.large_screens}`}>
        {slides?.map((slide, index) => {
          return (
            <div
              key={index}
              className={`${styles.slider} ${index === current ? styles.slider_active : ""
                }`}
            >
              {index === current && (
                <>
                  <div className={styles.image_container}>
                    <img src={slide.img} alt="Slide visual" />
                  </div>
                  <div className={styles.testimonial}>
                    {/* quote section */}
                    <div className={styles.quote_section}>
                      <div className={styles["base-layer"]}></div>
                      <div className={styles["middle-layer"]}></div>
                      <div className={styles["main-content"]}>
                        <div className={styles["quote"]}>
                          <div className={styles["quote_start"]}>
                            <img src={quoteStart} height={90} width={90} />
                          </div>
                          <div className={styles["quote_text"]}>
                            {slide.quote}
                          </div>
                          <div className={styles["quote_end"]}>
                            <img src={quoteEnd} />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <p className={styles["title"]}>{slide.author}</p>
                          </div>
                          <div
                            style={{
                              height: "1.3px",
                              width: "100%",
                              border: "1px solid #FF7700",
                            }}
                          ></div>
                        </div>
                        <div style={{ marginTop: "16px" }}>
                          <img src={slide.logo} height="36px" />
                        </div>
                        {slide.metrics.length===1 && (
                          <>
                              <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "1rem 0px",
                            gap: "2rem",
                          }}
                        >
                          <MetricCard
                            count={slide.metrics[0]?.number}
                            text={slide.metrics[0]?.description}
                          />
                          {slide.metrics.length === 2 && (
                            <>
                              <div
                                style={{
                                  height: "100px",
                                  width: "1px",
                                  border: "1px solid #ff7700",
                                }}
                              />
                              <MetricCard
                                count={slide.metrics[1]?.number}
                                text={slide.metrics[1]?.description}
                              />
                            </>
                          )}

                        </div>
                          </>
                        )}
                        

                        <div className={styles.controls_container}>
                          {/* button controls */}
                          <div className={styles.btn_controls_container}>
                            <button
                              onClick={() =>
                                current === 0
                                  ? null
                                  : handleButtonControl("back")
                              }
                            >
                              <img
                                src={
                                  current === 0
                                    ? back_disabled_btn
                                    : back_active_btn
                                }
                              />
                            </button>
                            <button
                              onClick={() =>
                                current === slides?.length - 1
                                  ? null
                                  : handleButtonControl("next")
                              }
                            >
                              <img
                                src={
                                  current === slides?.length - 1
                                    ? next_disabled_btn
                                    : next_active_btn
                                }
                              />
                            </button>
                          </div>

                          {/* slider thumb */}
                          <div className={styles.slider_thumb_container}>
                            {slides?.map((slide, i) => {
                              return (
                                <button
                                  onClick={() => handleThumbSlideChange(i)}
                                  style={{
                                    color: i === current ? "#FF7700" : "gray",
                                  }}
                                >
                                  &#9679;
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={`${styles.small_screens} ${styles.mobile_slider_container}`}
      >
        {/* slides */}
        <div>
          {slides?.map((slide, i) => {
            return (
              <>
                {i === current && (
                  <div className={styles.mb_quote_section}>
                    <div className={styles["mb_base_layer"]}></div>
                    <div className={styles["mb_middle_layer"]}></div>
                    <div className={styles["mb_main_content"]}>
                      <div style={{ display: "flex" }}>
                        {/* text area */}
                        <div className={styles.mb_text_content}>
                          <div className={styles.text_container}>
                            <div className={styles["quote_start"]}>
                              <img src={quoteStart} height={30} width={30} />
                            </div>
                            <div className={styles["quote_main"]}>
                              {slide.quote}
                            </div>
                            <div className={styles["quote_end"]}>
                              <img src={quoteEnd} height={20} width={20} />
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <p className={styles.name_title}>
                                  {slide.author}
                                </p>
                              </div>
                              <div
                                style={{
                                  height: "1.3px",
                                  width: "100%",
                                  border: "1px solid #FF7700",
                                }}
                              ></div>
                            </div>
                            <div style={{marginBottom:'8px'}}>
                              <img src={slide.logo} height="20px" />
                            </div>
                          </div>
                        </div>
                        {/* img area */}
                        <div className={styles.mb_img_content}>
                          <div className={styles.mb_img_content__container}>
                            <img src={slide.img} />
                          </div>
                        </div>
                      </div>
                      {/* metrics */}
                      {
                        slide.metrics.length === 1 && (
                          <>
                            <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "1rem 0.5rem",
                          gap: "1rem",
                        }}
                      >
                        <MetricCard
                          count={slide.metrics[0]?.number}
                          text={slide.metrics[0]?.description}
                        />

                        {
                          slide.metrics.length === 2 && (
                            <>
                              <div
                                style={{
                                  height: "60px",
                                  width: "1px",
                                  border: "1px solid #ff7700",
                                }}
                              />
                              <MetricCard
                                count={slide.metrics[1]?.number}
                                text={slide.metrics[1]?.description}
                              />
                            </>
                          )
                        }

                      </div>
                          </>
                        )
                      }
                      
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
        {/* button controls */}
        <div className={styles.mb_btn_container}>
          <button
            onClick={() => (current === 0 ? null : handleButtonControl("back"))}
          >
            <img src={current === 0 ? back_disabled_btn : back_active_btn} />
          </button>
          <button
            onClick={() =>
              current === slides?.length - 1
                ? null
                : handleButtonControl("next")
            }
          >
            <img
              src={
                current === slides?.length - 1
                  ? next_disabled_btn
                  : next_active_btn
              }
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default Slider;
