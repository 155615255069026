import React, { useState, useEffect } from "react";
import style from "./styles.module.css";
import { Link } from "react-router-dom";
import lynkit_logo from "../../assets/header/lynkit_logo.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Header = ({ setSelectedFeature }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [scrollingUp, setScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false); // Track if in mobile view
  const location = useLocation();

  useEffect(() => {
    // Scroll to the element specified by the hash
    if (location.hash) {
      const element = document.querySelector(location.hash.replace(/\/+/g, '\\/'));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      // If no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 980); // Mobile if width is less than or equal to 980px
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleLinkClick = () => {
    // Close the menu if it's open in mobile view
    if (isMobileView) {
      setIsOpen(false);
    }
  };

  const menuItems = [
    { label: "Home", link: "/xeneta#", tgt: "_self" },
    {
      label: "Key Features",
      link: "/xeneta#features",
    },
    { label: "Pricing", link: "/xeneta#lane", tgt: "_self" },
    {
      label: "About Us",
      link: "https://www.lynkit.in/About_us",
      tgt: "_blank",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollingUp(false);
      } else {
        setScrollingUp(true);
      }
      setLastScrollY(window.scrollY);
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <header
      className={`${style.header} ${
        scrollingUp ? style.showHeader : style.hideHeader
      } ${scrolled || isOpen ? style.scrolled : ""} ${
        isOpen ? style.menuOpen : ""
      }`}
    >
      <div className={style.container}>
        <div className={style.logo}>
          <Link to={"/xeneta#"} target="_self">
            <img src={lynkit_logo} alt="Lynkit Logo" />
          </Link>
        </div>
        <nav className={`${style.nav} ${isOpen ? style.open : ""}`}>
          <ul className={style["nav-list"]}>
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={style["nav-item"]}
                onClick={handleLinkClick} // Close menu on click
              >
                {item.subItems ? (
                  // Render as a button if there are subItems
                  <button
                    className={style["nav-link"]}
                    onClick={() => toggleSubmenu(index)}
                  >
                    {item.label}
                  </button>
                ) : (
                  // Render as a Link for navigation
                  <Link
                    to={item.link}
                    className={style["nav-link"]}
                    target={item.tgt}
                    onClick={handleLinkClick} // Close menu on click
                  >
                    {item.label}
                  </Link>
                )}
                <div className={style.gradient_line}></div>
              </li>
            ))}
          </ul>
          <button
            className={style.filled_gray}
            id={style.login_btn}
            onClick={() => {
              window.open("https://xeneta.lynkit.in/login", "_blank");
            }}
          >
            Login
          </button>
          <button
            className={style.filled_orange}
            id={style.contact}
            onClick={() => {
              window.open("https://www.lynkit.in/ContactUs", "_blank");
            }}
          >
            Contact Us
          </button>
        </nav>
        <button className={style["hamburger"]} onClick={toggleMenu}>
          {isOpen ? (
            <span className={style.cross}>✖</span>
          ) : (
            <>
              <span className={style["hamburger-line"]}></span>
              <span className={style["hamburger-line"]}></span>
              <span className={style["hamburger-line"]}></span>
            </>
          )}
        </button>

        <div className={style.action_btn_container}>
          <button
            className={style.filled_gray}
            onClick={() => {
              window.open("https://xeneta.lynkit.in/login", "_blank");
            }}
          >
            Login
          </button>
          <button
            className={style.filled_orange}
            onClick={() => {
              window.open("https://www.lynkit.in/ContactUs", "_blank");
            }}
          >
            Contact Us
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
