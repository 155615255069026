import React, { useState } from "react";
import styles from "./styles.module.css";
import { StyledHeading } from "../../HelperComponents";
import portable_gps_lock from "../../assets/hardware/portable_gps_lock.svg";
import asset_tracker from "../../assets/hardware/asset_tracker.svg";
import { SlideUpComponent } from "../../AnimationConents";
import rfid_antenna from '../../assets/hardware/rfid_antenna.svg'
import boom from '../../assets/hardware/boom.svg'
import rfid_reader from '../../assets/hardware/rfid_reader.png'
import rfid_tag from '../../assets/hardware/rfid_tag.svg'
import fixed_lock from '../../assets/hardware/fixed_lock.svg'
import fixed_tracker from '../../assets/hardware/fixed_tracker.svg'
import fuel_sensor from '../../assets/hardware/fuel_sensor.svg'
import OBD_tracker from '../../assets/hardware/OBD_tracker.svg'
const products = [
  {
    id: 1,
    title: "Portable GPS Lock",
    image: portable_gps_lock,
    details:
      "Lynkit GPS locks with 4G connectivity, 2G fallback, Dual sim support and 2.5M accuracy.",
  },
  {
    id: 2,
    title: "Asset Trackers",
    image: asset_tracker,
    details:
      "GPS asset trackers with Rechargeable batteries built-in 7500mAh or 10000mAh configurations.",
  },
  {
    id: 3,
    title: "Fuel Sensor",
    image: fuel_sensor,
    details: "The fuel sensor is used to get consumption patterns and mileage calculations.",
  },
  {
    id: 4,
    title: "OBD Tracker",
    image: OBD_tracker,
    details:
      "OBD vehicle tracking with 4G communication/2G fallback and GPS tracking.",
  },
  {
    id: 5,
    title: "Fixed Lock",
    image: fixed_lock,
    details:
      "Lynkit fixed locks with 4G connectivity, 2G fallback, and an incorrect password-locking system.",
  },
  {
    id: 6,
    title: "Fixed Tracker",
    image: fixed_tracker,
    details: "4G communication with 2G fallback and GPS tracking.",
  },
  {
    id: 7,
    title: "RFID Antenna",
    image: rfid_antenna,
    details:
      " Connected to the fixed reader, each antenna will be linked to a different location, such as lane 1, 2, or gate in/out, even though connected to the same reader.",
  },
  {
    id: 8,
    title: "Boom Barrier",
    image: boom,
    details:"Designed for uninterrupted traffic movement",
  },
  {
    id: 9,
    title: "RFID Reader",
    image: rfid_reader,
    details:
      "Placed in areas where fixed antennas & reading points are needed within close proximity, used in and out of gates through which material and trucks pass.",
  },
  {
    id: 10,
    title: "RFID Tags",
    image: rfid_tag,
    details:
      "Passive tags to be used for multiple asset types as Metallic, plastic, etc., can either be hung, pasted, or screwed on the assets.",
  },
  
];

const gradients = [
  styles.gradientTopLeft,
  styles.gradientBottomLeft,
  styles.gradientBottomRight,
  styles.gradientTopRight
];

const Hardware = () => {
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleProductClick = (id) => {
    setSelectedProductId(selectedProductId === id ? null : id);
  };

  const getRandomGradient = () => {
    return gradients[Math.floor(Math.random() * gradients.length)];
  };

  return (
    <div className={styles.hardware_container_outer}>
      <div>
        <StyledHeading text="Hardware Products" />
        <SlideUpComponent duration={1500}>
          <p className={styles.heading_para}>Explore The Awesome</p>
        </SlideUpComponent>
        
      </div>
      <div className={styles.hardware_products_container}>
        <div className={styles.product_grid}>
          {products.map((product) => (
            <div
              key={product.id}
              className={`${styles.product_box} ${selectedProductId === product.id ? styles.selected : ""}`}
              onClick={() => handleProductClick(product.id)}
            >
              <div
                className={`${styles.product_img_div} ${getRandomGradient()}`}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  className={styles.product_image}
                />
              </div>

              <div
                className={`${styles.right_part} ${getRandomGradient()}`}
              >
                <h3 className={styles.product_title}>{product.title}</h3>

                {selectedProductId === product.id && (
                  <p className={styles.product_details}>{product.details}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
