// import React, { useState } from "react";
// import style from "./header.module.css";
// import lynktracLogo from "./lynktrac_5.webp";

// const features = [
//   {
//     label: "Account Configuration",
//     link: "#features",
//   },
//   {
//     label: "User Management",
//     link: "#features",
//   },
//   {
//     label: "Monitoring Dashboard",
//     link: "#features",
//   },
//   {
//     label: "Trip Management and Route Optimization",
//     link: "#features",
//   },
//   {
//     label: "Analytics",
//     link: "#features",
//   },
// ];

// const Header = ({ setSelectedFeature }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [openSubmenu, setOpenSubmenu] = useState(null);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleSubmenu = (index) => {
//     if (openSubmenu === index) {
//       setOpenSubmenu(null);
//     } else {
//       setOpenSubmenu(index);
//     }
//   };

//   const menuItems = [
//     { label: "Home", link: "#", tgt:'_self' },
//     {
//       label: "Key Features",
//       // link: "#",
//       subItems: features,
//     },
//     { label: "Use Cases", link: "#useCases", tgt:'_self' },
//     { label: "Hardware", link: "#hardware-products", tgt:'_self' },
//     { label: "About Us", link: "https://www.lynkit.in/About_us", tgt:'_blank' },
//   ];

//   return (
//     <header className={style["header"]}>
//       <div className={style["container"]}>
//         <div className={style["logo"]}>
//           <img src={lynktracLogo} />
//         </div>
//         <nav className={`${style["nav"]} ${isOpen ? style["open"] : ""}`}>
//           <ul className={style["nav-list"]}>
//             {menuItems.map((item, index) => (
//               <li key={index} className={style["nav-item"]}>
//                 <a
//                   target={item.tgt}
//                   href={item.subItems ? null : item.link}
//                   className={style["nav-link"]}
//                   onClick={() => item.subItems && toggleSubmenu(index)}
//                 >
//                   {item.label}
//                   {item.subItems && (
//                     <>
//                       {/* {openSubmenu === index ? (
//                         <KeyboardArrowUp sx={{ color: "#FF7700" }} />
//                       ) : (
//                         <KeyboardArrowDown sx={{ color: "#FF7700" }} />
//                       )} */}
//                        {openSubmenu === index ? (
//                         <span style={{color:'#FF7700'}}>&#11205;</span>
//                       ) : (
//                         <span style={{color:'#FF7700'}}>&#11206;</span>
//                       )}
//                     </>
//                   )}
//                 </a>
//                 {item.subItems && (
//                   <ul
//                     className={`${style["submenu"]} ${openSubmenu === index ? style["open"] : ""}`}
//                   >
//                     {item.subItems.map((subItem, subIndex) => (
//                       <li
//                         key={subIndex}
//                         className={`${style["nav-item"]} ${style["sub-nav-item"]}`}
//                         onClick={() => {
//                           setSelectedFeature(subIndex);
//                           setOpenSubmenu(null);
//                         }}
//                       >
//                         <a
//                           href={subItem.link}
//                           className={style["nav-link"]}
//                           onClick={() => {
//                             setSelectedFeature(subIndex);
//                             setOpenSubmenu(null);
//                           }}
//                         >
//                           {subItem.label}
//                         </a>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//           </ul>
//         </nav>
//         <button className={style["hamburger"]} onClick={toggleMenu}>
//           <span className={style["hamburger-line"]}></span>
//           <span className={style["hamburger-line"]}></span>
//           <span className={style["hamburger-line"]}></span>
//         </button>
//         <div className={style.action_btn_container}>
//           <button
//             className={style.filled_gray}
//           >
//             <a href="https://lynktrac.io/login" target="_blank" className={style.header_login_btn}>Login</a>
//           </button>
//           <button className={style.filled_orange} onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}>Request a Demo</button>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;



// ----------------- redirection logic below --------------------

import React, { useState } from "react";
import style from "./header.module.css";
import lynktracLogo from "./lynktrac_5.webp";
import downward from './downward.svg'
import upward from './upward.svg'
// import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
// import history from "../../../../../services/history";

const features = [
  {
    label: "Account Configuration",
    link: "#features",
  },
  {
    label: "User Management",
    link: "#features",
  },
  {
    label: "Monitoring Dashboard",
    link: "#features",
  },
  {
    label: "Trip Management and Route Optimization",
    link: "#features",
  },
  {
    label: "Analytics",
    link: "#features",
  },
];

const Header = ({ setSelectedFeature }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [parentUrl, setParentUrl] = useState(null);
  const [isInIframe, setIsInIframe] = useState(false);

  React.useEffect(() => {
    // Check if the page is loaded inside an iframe
    const checkIfInIframe = () => {
      try {
        setIsInIframe(window.self !== window.top);
      } catch (e) {
        // If accessing window.top throws an error, assume it's in an iframe
        setIsInIframe(true);
      }
    };

    checkIfInIframe();
  }, []);

  React.useEffect(() => {
    if (isInIframe) {
      // Listen for messages from the parent
      const handleMessage = (event) => {
        // Ensure the message is from a trusted origin (e.g., the parent app)
        if (!event.origin) {
          return; // Skip if the origin is not available
        }

        // Handle the message
        if (event.data.type === 'PARENT_URL_RESPONSE') {
          // Set the parent's URL in state
          setParentUrl(event.data.url);
        }
      };

      window.addEventListener('message', handleMessage);

      // Send a message to the parent to request its URL
      try {
        window.parent.postMessage({ type: 'REQUEST_PARENT_URL' }, '*');
      } catch (e) {
        console.error('Failed to communicate with the parent window:', e);
      }

      // Clean up the event listener
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
  }, [isInIframe]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = (index) => {
    if (openSubmenu === index) {
      setOpenSubmenu(null);
    } else {
      setOpenSubmenu(index);
    }
  };
  console.log('path>>>>>>>>>>>>', window?.location?.origin)
  const menuItems = [
    { label: "Home", link: "#", tgt:'_self' },
    {
      label: "Key Features",
      // link: "#",
      subItems: features,
    },
    { label: "Use Cases", link: "#useCases", tgt:'_self' },
    { label: "Hardware", link: "#hardware-products", tgt:'_self' },
    { label: "About Us", link: "https://www.lynkit.in/About_us", tgt:'_blank' },
  ];

  const handleButtonClick = () => {
    if (isInIframe && parentUrl) {
      window.top.location.href = `${parentUrl}login`;
    } else {
      window.location.href = 'https://lynktrac.io/login';
    }
  };

  const closeMenu = () => {
    if (window.innerWidth <= 980) { // Only close menu on mobile or small screens
      setIsOpen(false);
    }
  };

  return (
    <header className={style["header"]}>
      <div className={style["container"]}>
        <div className={style["logo"]}>
          <img src={lynktracLogo} />
        </div>
        <nav className={`${style["nav"]} ${isOpen ? style["open"] : ""}`}>
          <ul className={style["nav-list"]}>
            {menuItems.map((item, index) => (
              <li 
                key={index} 
                className={style["nav-item"]} 
                onClick={() =>{
                  if(!item.subItems){
                    closeMenu()
                  }
                  
                } 
                }>
                <a
                  target={item.tgt}
                  href={item.subItems ? null : item.link}
                  className={style["nav-link"]}
                  onClick={() => item.subItems && toggleSubmenu(index)}
                >
                  {item.label}
                  {item.subItems && (
                    <>
                      {/* {openSubmenu === index ? (
                        <KeyboardArrowUp sx={{ color: "#FF7700" }} />
                      ) : (
                        <KeyboardArrowDown sx={{ color: "#FF7700" }} />
                      )} */}
                       {openSubmenu === index ? (
                        <span className={style.arrows} style={{color:'#FF7700'}}><img height='10px' width="10px" src={upward} style={{color:"#ff7700"}}/></span>
                      ) : (
                        <span className={style.arrows} style={{color:'#FF7700'}}><img height='10px' width="10px" src={downward} style={{color:"#ff7700"}}/></span>
                      )}
                    </>
                  )}
                </a>
                {item.subItems && (
                  <ul
                    className={`${style["submenu"]} ${openSubmenu === index ? style["open"] : ""}`}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`${style["nav-item"]} ${style["sub-nav-item"]}`}
                        // onClick={() => {
                        //   setSelectedFeature(subIndex);
                        //   setOpenSubmenu(null);
                        // }}
                        onClick={()=>closeMenu()}
                      >
                        <a
                          href={subItem.link}
                          className={style["nav-link"]}
                          onClick={() => {
                            setSelectedFeature(subIndex);
                            setOpenSubmenu(null);
                          }}
                        >
                          {subItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <button className={style["hamburger"]} onClick={toggleMenu}>
          <span className={style["hamburger-line"]}></span>
          <span className={style["hamburger-line"]}></span>
          <span className={style["hamburger-line"]}></span>
        </button>
        <div className={style.action_btn_container}>
          {/* <button
            className={style.filled_gray}
          >
            <a href="https://lynktrac.io/login" target="_blank" className={style.header_login_btn}>Login</a>
          </button> */}




          <button
            className={style.filled_gray}
          >
            <a 
              // href="/login" 
              onClick={(e) => {
                e.preventDefault();
                handleButtonClick();
              }} 
              className={style.header_login_btn}
            >
              Login
            </a>
            {/* {
              window?.location?.origin === "https://lynkit.in" ||
              window?.location?.origin === "https://www.lynkit.in" ||
              window?.location?.origin === "https://lynkittest.lynkit.in" || 
              window?.location?.origin === "https://www.lynkittest.lynkit.in" 
              ? (
                  <a href="https://lynktrac.io/login" target="_blank" className={style.header_login_btn}>Login</a>
                ) 
                :
                (
                  <a href={`${window?.location?.origin}/login`} target="_self" className={style.header_login_btn}>Login</a>
                )
            } */}
          </button>
          <button className={style.filled_orange} onClick={()=>{window.open('https://lynkit.in/ContactUs', '_blank')}}>Request a Demo</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
